import styled from "styled-components";

const ListDiv = styled.div`
  margin: 0;
  padding: 70px 0 120px;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 20px 0 90px;
  }
`;

export {
  ListDiv
}