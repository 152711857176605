import styled from "styled-components";

const AsgSection = styled.section`
  background-color: ${({ theme }) => theme.green_700};
  padding: 50px 0 90px;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 50px 20px 60px;
  }
`;

const AsgContainerDiv = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 20px;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    grid-template-columns: 1fr 1fr; 
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    grid-template-columns: 1fr;
  }
`;

export {
  AsgSection,
  AsgContainerDiv
}