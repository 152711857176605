import React, { FC } from 'react';

import { IInputGroup } from './interfaces';

import { InputGroupDiv } from './styles';

// input group
const InputGroup: FC<IInputGroup> = ({
  children
}) => {
  return (
    <InputGroupDiv>
      {children}
    </InputGroupDiv>
  );
};

export default InputGroup;