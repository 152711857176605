import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import ReactPlayer from 'react-player';

import Modal from "../Modal";
import OurOperationInfo from "./OurOperationInfo";

import { IOurOperation } from "./interfaces";

import { OurOperationDiv } from "./styles";

// our operation
const OurOperation: FC<IOurOperation> = ({
  lang
}) => {
  const [ openModal, setOpenModal ] = useState<boolean>(false); // open
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/operation-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <OurOperationDiv id="nossa-operacao">
      <Modal open={openModal} setOpen={setOpenModal} type="video">
        <ReactPlayer url={data?.video?.url} />
      </Modal>
      
      <OurOperationInfo
        {...data}
        lang={lang}
        setOpenModal={setOpenModal} />
    </OurOperationDiv>
  );
}

export default OurOperation;