import styled from "styled-components";

const PageInfoDiv = styled.div`
  padding-top: 140px;
  margin-bottom: 20px;
  width: 100%;

  .page {
    &--title,
    &--subtitle,
    &--text {
      margin: 0;
      width: 100%;
    }

    &--title {
      margin: 0 0 20px;
      line-height: 49.5px;
    }

    &--subtitle {
      font-weight: 600;
      line-height: 30.8px;
    }

    &--text {
      margin-top: 20px;
      font-size: 17px !important;
      line-height: 27.2px;
      white-space: pre-line;

      strong {
        text-decoration: underline;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 1024px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media only screen and (max-width : 768px) {
    padding-top: 90px;

    .page {
      &--title {
        font-size: 36px;
      }

      &--subtitle {
        font-size: 21px;
      }
    }
  }
`;

export {
    PageInfoDiv
}