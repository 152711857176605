import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';

import { H2 } from '../Typography/styles';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

import { ContactSection, ContactTitleDiv, ContactContainerDiv } from './styles';
import { ContainerDiv } from '../../layout/Container/styles';

import { IContact } from './interfaces';

// contact
const Contact: FC<IContact> = ({
  lang
}) => {
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/contact-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <ContactSection id="contato">
      <ContainerDiv>
        <ContactTitleDiv>
          <H2>{data?.title}</H2>
        </ContactTitleDiv>

        <ContactContainerDiv>
          <ContactInfo items={data?.items} />
          <ContactForm title={data?.subtitle} />
        </ContactContainerDiv>
      </ContainerDiv>
    </ContactSection>
  );
};

export default Contact;