import React, { FC } from 'react';

import { NewsArrowButton } from './styles';

import { INewsArrow } from './interfaces';

// news arrow
const NewsArrow: FC<INewsArrow> = ({
  className,
  onClick = false,
  icon
}) => {
  return (
    <NewsArrowButton className={className} onClick={onClick}>
      <span className={`icon ${icon}`}></span>
    </NewsArrowButton>
  );
};

export default NewsArrow;