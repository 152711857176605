import styled from "styled-components";

export const FormSelectWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
    
    @media only screen and (max-width : 1024px) {
        display: flex;
    }

    &:has(div):has(.react-dropdown-select):has(.react-dropdown-select-content):has(span) {
        label {
            font-size: 12px;
            top: 6px;
            transform: translateY(0);
        }
    }

    &:has(div):has(.react-dropdown-select):has(.react-dropdown-select-content):has(input[required]) {
        label:before {
            content: "*";
            margin-right: 3px;
            color: red;
        }
    }
    
    &[data-size=small] {
        div:has(.react-dropdown-select) {
            width: 160px;
        }
    }

    &[data-size=mid] {
        div:has(.react-dropdown-select) {
            width: 355px;
        }
    }

    &[data-size=large] {
        div:has(.react-dropdown-select) {
            width: 100%;
        }
    }

    label {
        font-weight: 600;
        font-size: 16px;
        color: ${({ theme }) => theme.blue_300};
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        transition: all ease-in-out 0.4s;
        z-index: 1;
        pointer-events: none;
    }

    div:has(.react-dropdown-select) {
        flex: 1;
        
        .react-dropdown-select {
            min-height: 48px;
            background-color: ${({ theme }) => theme.white_900};
            padding: 0;
            border: 1px solid ${({ theme }) => theme.white_700};
            border-radius: 5px;
            outline: none;
            box-shadow: none;

            .react-dropdown-select-content {
                padding: 18px 18px 0;
                font-family: Montserrat, sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 17.6px;
                color: ${({ theme }) => theme.black_300};

                &:has(span) {
                    label {
                        font-size: 12px;
                        top: 6px;
                        transform: translateY(0);
                    }
                }
    
                .react-dropdown-select-input {
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
    
            .react-dropdown-select-dropdown-handle {
                svg {
                    width: 14px;
                    height: auto;
                    fill: #068CA0;
                    transform: scale(2);
                }
    
                &:hover {
                    svg {
                        path {
                            stroke: none;
                        }
                    }
                }
            }
    
            &[aria-expanded='false'] {
                .react-dropdown-select-dropdown-handle {
                    width: 90%;
                    text-align: start;
                    position: absolute;
                    right: 20px;
                }
            }
    
            &[aria-expanded='true'] {
                .react-dropdown-select-dropdown-handle {
                    width: 90%;
                    text-align: end;
                    position: absolute;
                    right: 20px;
                }
            }
    
            .react-dropdown-select-dropdown {
                width: 100%;
                top: 103%;
    
                &[aria-expanded='true'] {
                    border-radius: 0 0 5px 5px;
                }
    
                .react-dropdown-select-item {
                    padding: 5px 18px;
                }
            }
        }
    }
`