import React, { FC, useEffect, useState } from "react";

import axios from "axios";
import TableFilter from "./TableFilter";
import Table from "./Table";
import { TableComponentDiv } from "./styles";
import { ButtonDefaultLink } from "../Form/Button/styles";

import * as XLSX from 'xlsx'

const { REACT_APP_API_URL } = process.env;

const TableComponent: FC<any> = () => {
  const [products, setProducts] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [months, setMonths] = useState<any>([]);
  const [messageBottomProduct, setMessageBottomProduct] = useState<any>();
  const [messageBottomUnity, setMessageBottomUnity] = useState<any>();
  const [searchResults, setSearchResults] = useState<any>([]);

  useEffect(() => {
    const filterProductsRequest = axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/tarifas/produtos/`,
    });
    const filterYearsRequest = axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/tarifas/anos/`,
    });
    const filterMonthsRequest = axios({
      method: "GET",
      url: `${REACT_APP_API_URL}/tarifas/meses/`,
    });

    axios
      .all([filterProductsRequest, filterYearsRequest, filterMonthsRequest])
      .then(
        axios.spread((...responses) => {
          const filterProducts = responses[0];
          const filterYears = responses[1];
          const filterMonths = responses[2];

          if (filterProducts.data) {
            setProducts(filterProducts.data);
          }

          if (filterYears.data) {
            setYears(filterYears.data);
          }

          if (filterMonths.data) {
            setMonths(filterMonths.data);
          }
        })
      );
  }, []);


  const filter = () => {
    let productId;
    let yearId;
    let monthId;
    
    const productsFilterInput = document.querySelector('.products .react-dropdown-select-content span')
    const yearsFilterInput = document.querySelector('.years .react-dropdown-select-content span')
    const monthsFilterInput = document.querySelector('.months .react-dropdown-select-content span')
    
    if (productsFilterInput) {
        Array.isArray(products) && products.forEach((item) => {
            if(item.nome === productsFilterInput.innerHTML) {
                productId = item.id;
                setMessageBottomProduct(item.nome);
                setMessageBottomUnity(item.unidade);
            }
        })
    }

    if (yearsFilterInput) {
        Array.isArray(years) && years.forEach((item) => {
            if(item.nome == yearsFilterInput.innerHTML) {
                yearId = item.ano;
            }
        })
    }

    if (monthsFilterInput) {
        Array.isArray(months) && months.forEach((item) => {
            if(item.nome === monthsFilterInput.innerHTML) {
                monthId = item.mes;
            }
        })
    }

    if (productId && yearId && monthId) {
      const filterSearchRequest = axios({
        method: "GET",
        url: `${REACT_APP_API_URL}/tarifas/?produto=${productId}&ano=${yearId}&mes=${monthId}`,
      });
  
      axios
        .all([filterSearchRequest])
        .then(
          axios.spread((...responses) => {
            const filterSearchResults = responses[0];
  
            if (filterSearchResults.data) {
              setSearchResults(filterSearchResults.data);
            }
          })
        );
    }
  }

  const downloadFunction = () => {
    const tableData = searchResults.map((tableColumn: { id: any; }) => {
      delete tableColumn.id
      return tableColumn
    })

    const workbook = XLSX.utils.book_new();
    const worksheet:any = XLSX.utils.json_to_sheet(tableData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
    XLSX.writeFile(workbook, 'table_sheet.xlsx');
  }

  return (
    <>
      <TableComponentDiv>
        <TableFilter 
          products={products} 
          years={years} 
          months={months} 
          functionClick={filter} 
          messageBottomProduct={messageBottomProduct}
          messageBottomUnity={messageBottomUnity} 
        />

        <Table searchResults={searchResults} />

        <ButtonDefaultLink 
          className="download-button" 
          rel="noopener noreferrer"
          onClick={downloadFunction}
        >
          Baixar Arquivo XLS
        </ButtonDefaultLink>
      </TableComponentDiv>
    </>
  );
};

export default TableComponent;
