import styled from "styled-components";

export const RegularButton = styled.button`
    width: fit-content;
    background-color: ${({ theme }) => theme.white_900};
    padding: 11px 12px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.green_300};
    color: var(--verde-intermediario, #4CC084);
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 13px;
    font-style: normal;
    line-height: 14.3px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
`;