import styled from "styled-components";

const ButtonDefault = styled.button`
  background-color: ${({ theme }) => theme.green_300};
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: ${({ theme }) => theme.white_900};
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 1em;
  outline: none;
  padding: 20px 40px;
  text-transform: uppercase;
`;

const ButtonDefaultLink = styled.a`
  background-color: ${({ theme }) => theme.green_700};
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: ${({ theme }) => theme.green_900};
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  outline: none;
  padding: 16px 30px;
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 768px){
    font-size: 15px;
    line-height: 17px;
    padding: 7px 30px;
  }

  &.disabled {
    background: #1D5141;
    color: #417666;
    pointer-events: none;
  }
`;

export {
  ButtonDefault,
  ButtonDefaultLink
}