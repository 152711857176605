import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { TextMin } from "../../components/Typography/styles";

import { ContainerDiv } from "../Container/styles";
import {
  FooterFooter,
  FooterLogoDiv,
  FooterPrivacidadeDiv,
  FooterSocialNetworksDiv,
  FooterAssinaturaDiv,
} from "./styles";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as AssinaturaSirius } from "../../assets/icons/assinatura-sirius.svg";
import { items } from "./items";

// footer
const Footer: FC<any> = (lang?) => {
  const { t } = useTranslation();

  // render
  return (
    <FooterFooter>
      <ContainerDiv>
        <FooterLogoDiv>
          <Logo />
          <TextMin>{t("footer.copyright")}</TextMin>
        </FooterLogoDiv>

        <FooterPrivacidadeDiv>
          <a href={t('footer.link_cookies')} target="_blank" className="link">
            {t("footer.cookies")}
          </a>
          <a href={t('footer.link_privacidade')} target="_blank" className="link">
            {t("footer.privacidade")}
          </a>
          <a href={t('footer.link_etica_e_conduta')} target="_blank" className="link">
            {t("footer.etica_e_conduta")}
          </a>
          {lang.lang.lang === 'pt' ?
          <a href={t('footer.link_transparencia')} target="_blank" className="link">
            {t("footer.transparencia")}
          </a>
          : ""}
        </FooterPrivacidadeDiv>

        <FooterSocialNetworksDiv>
          {items &&
            items.map(({ href, icon }, index: number) => (
              <a
                className="link"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <span className={`icon ${icon}`}></span>
              </a>
            ))}
        </FooterSocialNetworksDiv>

        <FooterAssinaturaDiv>
          <a className="assinatura-wrapper" href="https://www.sirius.com.br?utm_source=site+3rpetroleum&utm_medium=link">
            Criado por:
            <AssinaturaSirius/>
          </a>
        </FooterAssinaturaDiv>
      </ContainerDiv>
    </FooterFooter>
  );
};

export default Footer;
