import React, { FC } from 'react';

import { H1, H1Strong, Text } from '../../Typography/styles';

import { IWeAreItem } from './interfaces';

import { WeAreItemDiv } from './styles';

// we are item
const WeAreItem: FC<IWeAreItem> = ({
  index,
  title,
  type,
  description
}) => {
  // render
  return (
    <WeAreItemDiv data-type={type}>
      <H1 data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}>
        {type !== 1 && <H1Strong className="number">{index}</H1Strong>}

        {type !== 1 ? <span className="first">{title[0]}</span> : <H1Strong className="number">{title}</H1Strong>}
        {type !== 1 ? <span className="text">{title.substring(1)}</span> : ''}
      </H1>
      <Text data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}>{description}</Text>
    </WeAreItemDiv>
  );
};

export default WeAreItem;