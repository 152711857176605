import styled from 'styled-components';

const WebdoorInfoItemDiv = styled.div`
  display: none;
  float: left;
  padding: 0;
  pointer-events: auto;
  width: 100%;

  h1,
  p {
    color: ${({ theme }) => theme.white_900};
    margin: 0;
    opacity: 0;
    top: 0;
    transition: all 400ms cubic-bezier(0.4, 0.35, 0.25, 1);
    transition-delay: 400ms;
    width: 100%;
  }

  p {
    margin: 40px 0 0;
    line-height: 1.6em;
    transition-delay: 500ms;
  }

  .webdoor-info-item--link {
    display: block;
    margin: 40px 0 0;
    opacity: 0;
    top: -50px;
    transition: all 400ms cubic-bezier(0.4, 0.35, 0.25, 1);
    transition-delay: 700ms;
    text-decoration: none;

    .icon {
      color: ${({ theme }) => theme.white_900};
      font-size: 42px;
    }
  }

  &[data-active="true"] {
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    align-items: center;
    align-content: center;
    display: inline-flex;
    flex-flow: row wrap;
    height: 100%;
    justify-content: flex-start;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    h1 {
      font-size: 34px;
    }
  }

  &[data-animate="false"] {
    .webdoor-info-item--link {
      opacity: 1;
      top: 0;
    }

    h1,
    p {
      opacity: 1;
      top: 0;
    }
  }

  &[data-animate="true"] {
    .webdoor-info-item--link,
    h1,
    p {
      opacity: 0;
      top: 0;
    }

    .webdoor-info-item--link {
      top: -50px;
    }
  }
`;

export {
  WebdoorInfoItemDiv,
}