import styled from "styled-components";
import DownloadIcon  from '../../assets/icons/download-xls-icon.svg';

const TableComponentDiv = styled.div`
    width: 100%;
    margin-bottom: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .download-button {
        padding: 15px 32px;
        margin-top: 30px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        &::before {
            content: url(${DownloadIcon});
        }

        @media (max-width: 856px) {
            max-width: 335px;
            width: 100%;
            padding: 15px 0;
        }
    }

    @media only screen and (max-width : 1024px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`;

export {
    TableComponentDiv
}