import React, { FC, useState } from "react";
import Select from "react-dropdown-select";

import { IFormSelect } from "./interfaces";
import { FormSelectWrapper } from "./styles";

const FormSelect: FC<IFormSelect> = ({
    size,
    name,
    label,
    required,
    options,
    labelField,
    valueField,
    callbackFunction
}) => {    
    return (
        <>
            <FormSelectWrapper data-size={size} className="form-field-wrapper">
                <label>{label}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    labelField={labelField}
                    valueField={valueField}
                    onChange={callbackFunction ? callbackFunction : (value) => console.log(value[0].id)}
                    values={[]}
                    placeholder=''
                    searchable={false}
                />
            </FormSelectWrapper>
        </>
    );
};

export default FormSelect;

