import styled from "styled-components";

const InputLabel = styled.label`
  float: left;
  margin: 10px 0;
  position: relative;
  width: 100%;

  .label {
    color: ${({ theme }) => theme.blue_300};
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    left: 20px;
    pointer-events: none;
    position: absolute;
    transition: all 500ms cubic-bezier(0.4,0.35,0.25,1);
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
  }

  .error {
    font-size: 14px;
    color: ${({ theme }) => theme.red_900};
    font-weight: 400;
    margin: 3px 0 5px;
    width: 100%;
  }

  .info {
    color: ${({ theme }) => theme.black_300};
    font-size: 12px;
    float: left;
    font-weight: 400;
    text-align: right;
    width: 100%;
  }

  input,
  textarea {
    border: 1px solid ${({ theme }) => theme.white_700};
    border-radius: 5px;
    color: ${({ theme }) => theme.black_300};
    height: 48px;
    font-family: Montserrat;
    font-size: 16px;
    outline: none;
    padding: 25px 20px 10px;
    width: 100%;
    z-index: 1;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.white_700};
    height: auto;
    padding-top: 15px;
    resize: none;

    & + .info + .label {
      font-weight: 600;
      font-size: 16px;
      pointer-events: none;
      top: 15px;
    }
  }

  &[data-error="true"] {
    textarea,
    input {
      border-color: ${({ theme }) => theme.red_900};
      color: ${({ theme }) => theme.red_900};
    }

    textarea {
      & + .info + .label {
        font-size: 16px;
      }
    }

    &[data-type="select"] {
      .select {
        &-title {
          border: 1px solid ${({ theme }) => theme.red_900};
        }
      }
    }
  }

  input {
    &:focus {
      & + .label {
        font-size: 12px;
        font-weight: 400;
        top: 5px;
        transform: translate(0, 0);
      }
    }

    &:required[value=""] {
      border-color: ${({ theme }) => theme.white_700};
    }
  }

  &[data-empty="true"] {
    .label {
      font-weight: 400;
      font-size: 12px;
      top: 5px;
      transform: translate(0, 0);
    }
  }

  &[data-empty="false"] {
    textarea {
      & + .info + .label {
        display: none;
      }
    }
  }

  &[data-type="check"] {
    display: inline-flex;
    pointer-events: none;

    input {
      cursor: pointer;
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      pointer-events: auto;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      & + .icon {
        float: left;
        height: 16px;
        position: relative;
        width: 16px;
        vertical-align: top;

        &:before,
        &:after {
          left: 0;
          position: absolute;
        }

        &:before {
          border: solid ${({ theme }) => theme.blue_300};
          border-width: 0 2px 2px 0;
          content: '';
          display: none;
          height: 8px;
          left: 6px;
          top: 0;
          transform: rotate(45deg) translate(2px, 2px);
          width: 4px;
          z-index: 1;
        }
        
        &:after {
          background-color: ${({ theme }) => theme.white_900};
          border: 1px solid ${({ theme }) => theme.blue_300};
          border-radius: 3px;
          height: 100%;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
        }
      }

      &:checked + .icon {
        &:before {
          display: inline-block;
        }
      }

      & + .icon + .label {
        color: ${({ theme }) => theme.black_300};
        top: 2px;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
`;

export {
  InputLabel
}