import styled from "styled-components";

const HeaderHeader = styled.header`
  height: 75px;
  position: fixed;
  opacity: 0;
  top: -100px;
  transition: all 400ms cubic-bezier(0.79, 0.6, 0.25, 1);
  transition-delay: 300ms;
  width: 100%;
  z-index: 99;

  &:before {
    background-color: ${({ theme }) => theme.blue_400};
    content: '';
    left: 0;
    height: 0%;
    position: absolute;
    transition: all 400ms cubic-bezier(0.4, 0.35, 0.25, 1);
    top: 0;
    width: 100%;
  }

  &[data-active="true"] {
    &:before {
      height: 90px;
    }
  }

  &[data-type="2"] {
    &:before {
      background-color: ${({ theme }) => theme.green_100};
      height: 100%;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: 55px;

    &[data-open="true"] {
      &:before {
        height: 0;
      }
    }

    &[data-active="true"] {
      &:before {
        height: 60px;
      }
    }
  }

  @media only screen and (max-width : 768px) {
    &:has(div):has(.links-wrapper) {
      height: 106px;

      &[data-active="true"] {
        &:before {
          height: 110px;
        }
      }
    }
  }

  &[data-animate="false"] {
    opacity: 1;
    top: 0;
  }

  &[data-animate="true"] {
    opacity: 0;
    top: -100px;
  }
`;

const HeaderLogoDiv = styled.div`
  float: left;
  left: -100px;
  top: -2px;
  z-index: 1;

  svg {
    height: 99px;
    width: auto;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1599px) {
    left: -40px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    left: 0px;

    svg {
      height: 60px;
    }
  }
`;

const HeaderOpenButton = styled.button`
  background: transparent;
  border: none;
  display: none;
  float: right;
  outline: none;
  padding: 0;
  top: 20px;

  .icon {
    color: ${({ theme }) => theme.white_900};
    display: none;
    font-size: 14px;

    &.icon-hamburguer {
      display: inline-block;
    }
  }

  &[data-active="true"] {
    .icon {
      display: none;

      &.icon-times {
        display: inline-block;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    display: inline-block;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin-right: 20px;
  }
`;

export {
  HeaderHeader,
  HeaderLogoDiv,
  HeaderOpenButton
}