import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';

import WeAreItem from './WeAreItem';

import { ContainerDiv } from '../../layout/Container/styles';
import { WeAreContainerDiv, WeAreDiv } from './styles';

import { IWeAre } from './interfaces';

// weAre
const WeAre: FC<IWeAre> = ({
  lang
}) => {
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/we-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <WeAreDiv id="quem-somos">
      <ContainerDiv>
        <WeAreContainerDiv>
          {Array.isArray(data) && data.map((item: any, index: number) =>
            <WeAreItem {...item} index={index} key={index} />)}
        </WeAreContainerDiv>
      </ContainerDiv>
    </WeAreDiv>
  );
};

export default WeAre;