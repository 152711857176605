import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';

import WebdoorContent from './WebdoorContent';

import { WebdoorDiv } from './styles';

import { IWebdoor } from './interfaces';

// webdoor
const Webdoor: FC<IWebdoor> = ({
  lang,
  animate
}) => {
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/webdoor-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <WebdoorDiv id="webdoor">
      <WebdoorContent
        items={data}
        animate={animate} />
    </WebdoorDiv>
  );
};

export default Webdoor;