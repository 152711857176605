import styled from "styled-components";

const TableFilterDiv = styled.div`
    max-width: 855px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 26px;

    @media (max-width: 856px) {
        padding: 0 20px;
        flex-direction: column;
    }

    div {
        &:has(.dropdown-filter) {
            width: 100%;
        }

        &:has(.dropdown-filter.products) {
            max-width: 358px;
        }

        &:has(.dropdown-filter.years),
        &:has(.dropdown-filter.months) {
            max-width: 160px;
        }

        @media (max-width: 856px) {
            &:has(.dropdown-filter.products),
            &:has(.dropdown-filter.years),
            &:has(.dropdown-filter.months) {
                max-width: 335px;
            }
        }
    }
    
    .dropdown-filter {
        height: 48px;
        padding-left: 18px;
        border: 1px solid #CFCFCF;
        border-radius: 5px;

        .react-dropdown-select-content {
            font-weight: 600;
            color: #068CA0;

            .react-dropdown-select-input {
                width: 100%;
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;

                &::placeholder {
                    font-weight: 600;
                    font-size: 16px;
                    color: #068CA0;
                }
            }
        }

        .react-dropdown-select-dropdown-handle {
            svg {
                width: 14px;
                height: auto;
                fill: #068CA0;
                transform: scale(2);
            }

            &:hover {
                svg {
                    path {
                        stroke: none;
                    }
                }
            }
        }

        &[aria-expanded='false'] {
            .react-dropdown-select-dropdown-handle {
                width: 90%;
                text-align: start;
                position: absolute;
                right: 20px;
            }
        }

        &[aria-expanded='true'] {
            .react-dropdown-select-dropdown-handle {
                width: 90%;
                text-align: end;
                position: absolute;
                right: 20px;
            }
        }

        .react-dropdown-select-dropdown {
            width: 100%;
            max-height: 150px;
            top: 105%;

            &[aria-expanded='true'] {
                border-radius: 0 0 5px 5px;
            }

            .react-dropdown-select-item {
                padding: 5px 18px;
            }
        }
    }

    a.search-button {
        padding: 16px 15px;
        margin: 0;
        margin-left: 8px;
        cursor: pointer;

        @media (max-width: 856px) {
            max-width: 335px;
            width: 100%;
            margin: 0;
        }
    }    
`;

const FilterBottomMessage = styled.div`
    width: 100%;
    padding: 0 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;

    span {
        width: 855px;
        font-weight: 500;
        font-size: 13px;
        line-height: 14.5px;
        color: #215B4A;

        &#filter-bottom-unity {
            width: fit-content;
            text-transform: uppercase;
        }
    }
`;

export {
    TableFilterDiv,
    FilterBottomMessage
}