import React, { FC } from 'react';

import { IOurOperationVideo } from './interfaces';

import { TextSubtitleMin } from '../../Typography/styles';
import { OurOperationVideoDiv, OurOperationVideoButton } from './styles';

const { PUBLIC_URL } = process.env;

// our operation video
const OurOperationVideo: FC<IOurOperationVideo> = ({
  image,
  time,
  title,
  setOpenModal
}) => {
  // render
  return (
    <OurOperationVideoDiv>
      <OurOperationVideoButton onClick={() => setOpenModal(true)}>
        <img
          className="our-operation-video--image"
          src={`${PUBLIC_URL}${image}`}
          alt="video" />

        <div className="our-operation-video--info">
          <div className="our-operation-video--icon">
            <span className="icon icon-line"></span>
            <span className="icon icon-play"></span>
          </div>

          <TextSubtitleMin className="title">{title}</TextSubtitleMin>
          <TextSubtitleMin className="time">{time}</TextSubtitleMin>
        </div>
      </OurOperationVideoButton>
    </OurOperationVideoDiv>
  );
};

export default OurOperationVideo;