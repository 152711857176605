import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import MessagePrivacidade from '../../components/MessagePrivacidade';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

import { IPrivacidade } from './interfaces';
import { InternaDiv } from './styles';

// Interna
const Privacidade: FC<IPrivacidade> = ({
  animate,
  lang
}) => {
  const [ data, setData ] = useState<any>({});

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/privacidade-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <InternaDiv>
      <Header
        animate={animate}
        menu={[]}
        subMenu={[]}
        type={2} />

      {data && <MessagePrivacidade {...data} />}

      <Footer lang={lang} />
    </InternaDiv>
  );
};

export default Privacidade;