import styled from "styled-components";

const SelectLangDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid ${({ theme }) => theme.green_900};
  width: 55px;

  .select-lang {
    &--item {
      background: transparent;
      border: none;
      color: ${({ theme }) => theme.white_900};
      font-size: 10px;
      font-weight: 500;
      outline: none;
      padding: 5px 10px;
      text-align: left;
      text-transform: uppercase;
      width: 100%;

      .icon {
        color: ${({ theme }) => theme.white_900};
        font-size: 8px;
        right: 10px;
        position: absolute;
        top: 7px;
        transform: rotate(90deg);
      }

      &:hover {
        color: ${({ theme }) => theme.white_900};
      }
    }
  }

  &[data-active="true"] {
    > .select-lang--item {
      .icon {
        transform: rotate(-90deg);
        top: 6px;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    &[data-mobile="true"] {
      background-color: transparent;
      margin: 50px 0 0;
      width: 100%;

      .select-lang {
        &--item {
          display: none;
        }
      }
    }
  }
`;

const SelectLangListUl = styled.ul`
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid ${({ theme }) => theme.green_900};
  border-top: none;
  display: none;
  margin: 0;
  left: -1px;
  list-style: none;
  padding: 0;
  position: absolute;
  top: 100%;
  width: calc(100% + 2px);

  .select-lang--list--item {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.green_900};
    width: 100%;

    .select-lang--item {
      color: ${({ theme }) => theme.green_700};
    }

    &[data-active="true"] {
      display: none;
    }
  }

  &[data-active="true"] {
    display: inline-block;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    &[data-mobile="true"] {
      background-color: transparent;
      display: inline-block;
      
      .select-lang--list--item {
        background-color: rgba(0,0,0,0.4);
        border: none;
        margin: 0 20px 0 0;
        width: auto;
  
        &[data-active] {
          border: 1px solid ${({ theme }) => theme.green_900};
          display: inline-block;
          
          .select-lang--item {
            color: ${({ theme }) => theme.white_900};
            display: inline-block;
          }
        }
  
        &[data-active="true"] {
          border: 1px solid ${({ theme }) => theme.green_700};
  
          .select-lang--item {
            color: ${({ theme }) => theme.green_700};
          }
        }
      }
    }
  }
`;

export {
  SelectLangDiv,
  SelectLangListUl,
}