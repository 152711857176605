import React, { FC } from "react";
import { createPortal } from "react-dom";

import { IModal } from "./interfaces";

import { ModalDiv,ModalContainerDiv, ModalHeaderDiv, ModalBodyDiv } from "./styles";

// modal
const modal: any = document.getElementById('modal') as HTMLElement;

// modal
const Modal: FC<IModal> = ({
  children,
  open,
  type,
  setOpen
}) => {
  // render
  return (
    <>
      {modal && open && createPortal(<ModalDiv>
        <ModalContainerDiv data-type={type}>
          <ModalHeaderDiv data-type={type}>
            <button onClick={() => setOpen(!open)}>
              <span className="icon icon-times"></span>
            </button>
          </ModalHeaderDiv>

          <ModalBodyDiv data-type={type}>
            {children}
          </ModalBodyDiv>
        </ModalContainerDiv>
      </ModalDiv>, modal)}
    </>
  );
};

export default Modal;