const items: string[] = [
  'status',
  'contract_signature',
  'acquisition_completion',
  'localization',
  'basin',
  'type',
  'participation_3r',
  'reservations'
];

export {
  items,
}