import styled from "styled-components";

const NewsItemDiv = styled.div`
  background-color: transparent;
  float: left;
  max-width: 440px;
  padding: 10px 25px 30px;
  width: calc(100% - 20px);

  .link {
    bottom: 20px;
    color: ${({ theme }) => theme.blue_300};
    font-weight: 600;
    font-size: 15px;
    right: 20px;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
  }

  &:before {
    background-color: ${({ theme }) => theme.white_900};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    width: calc(100% - 20px);
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 10px 15px 30px;
    width: calc(100% - 15px);
  }
`;

const NewsItemContentDiv = styled.div`
  float: left;
  margin: 0;
  padding: 0 0 10px;
  width: 100%;

  .description,
  .title {
    float: left;
    margin: 0;
    width: 100%;
  }

  .description {
    color: ${({ theme }) => theme.black_300};
    line-height: 1.5em;
  }

  .title {
    color: ${({ theme }) => theme.green_900};
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .title {
      margin: 0 0 20px;
    }
  }
`;

const NewsItemTitleDiv = styled.div`
  float: left;
  padding: 40px 0;
  width: 100%;

  .date,
  .type {
    color: ${({ theme }) => theme.green_900};
    font-weight: 400;
    margin: 0;
  }

  .date {
    font-size: 15px;
    font-weight: 600;
    right: 0px;
    position: absolute;
    text-align: center;
    width: 90px;

    &:after {
      background-color: ${({ theme }) => theme.green_300};
      content: '';
      opacity: 0.2;
      position: absolute;
    }

    &:after {
      border-radius: 100%;
      height: 78px;
      left: 50%;
      top: -30px;
      transform: translate(-50%, 0);
      width: 78px;
    }
  }

  .type {
    padding: 0 85px 0 0;
    width: 100%;
    white-space: nowrap;
    
    &:before {
      background-color: ${({ theme }) => theme.green_300};
      content: '';
      opacity: 0.2;
      position: absolute;
    }

    &:before {
      bottom: -6px;
      height: 2px;
      width: calc(100% - 80px);
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .date {
      font-size: 15px;
    }

    .type {
      font-size: 13px;
    }
  }
`;


export {
  NewsItemDiv,
  NewsItemContentDiv,
  NewsItemTitleDiv
}