import styled from "styled-components";

const Banner = styled.div`
    width: 100%;
    height: 74px;
    padding: 13px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0B3F30;
    opacity: 0.85;
    position: fixed;
    bottom: 0;
    z-index: 3;

    @media only screen and (max-width : 1599px) {
        height: 90px;
    }

    @media only screen and (max-width : 768px) {
        height: 230px;
        padding: 13px 10px;
    }
`

const TextBanner = styled.p`
    max-width: 1251px;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    margin: 0;

    a {
        color: #A1D64A;
        font-weight: 600;
        text-decoration: none;
        transition: all .3s ease-in-out;

        &:hover {
            color: #4CC084;
        }
    }

    @media only screen and (max-width : 1599px) {
        width: 85%;
    }

    @media only screen and (max-width : 768px) {
        width: 70%;
    }
`
const ButtonBanner = styled.button`
    width: 105px;
    height: 29px;
    padding: 9px 0;
    text-align: center;
    border-radius: 5px;
    border: none;
    background: #FFFFFF;
    color: #215B4A;
    font-size: 12px;
    line-height: 13px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all .3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: #068CA0;
    }

    @media only screen and (max-width : 1599px) {
        width: 10%;
    }

    @media only screen and (max-width : 768px) {
        width: 25%;
    }
`

export {
    Banner,
    TextBanner,
    ButtonBanner
  }