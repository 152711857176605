import { useEffect } from "react";

function useOutsideRef(ref: any, callback: any, type = "hover") {
  // user effect
  useEffect(() => {
    function handleOutside(event: any) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        callback();
      }
    }

    if (type === "hover") {
      document.addEventListener("keyup", handleOutside);
      document.addEventListener("mouseover", handleOutside);

      return () => {
        document.removeEventListener("keyup", handleOutside);
        document.removeEventListener("mouseover", handleOutside);
      };
    } else {
      document.addEventListener("keyup", handleOutside);
      document.addEventListener("mousedown", handleOutside);
      
      return () => {
        document.removeEventListener("keyup", handleOutside);
        document.removeEventListener("mousedown", handleOutside);
      };
    }

  }, [ref, callback, type]);
}

export default useOutsideRef;
