import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';

import Main from "./layout/Main";

import MainProvider from "./providers/MainProvider";
import LangProvider from "./providers/LangProvider";
import ThemeProvider from "./providers/ThemeProvider";

import "animate.css/animate.css";
import "./index.css";

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <LangProvider>
        <MainProvider>
          <ThemeProvider>
            <Main />
          </ThemeProvider>
        </MainProvider>
      </LangProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);