import React, { FC } from "react";
import { IFormField } from "./interfaces";
import { FormFieldWrapper } from "./styles";

const FormField: FC<IFormField> = ({
    name,
    label,
    inputType,
    required,
    placeholder,
    dataMask,
}) => {
    return (
        <>
            <FormFieldWrapper className="form-field-wrapper">
                <label>{label}</label>
                <input name={name} type={inputType} required={required} placeholder={placeholder} data-mask={dataMask} />
            </FormFieldWrapper>
        </>
    );
};

export default FormField;

