import styled from "styled-components";

const AsgTitleDiv = styled.div`
  text-align: center;
  width: 100%;
`;

const AsgTitleContainerDiv = styled.div`
  float: none;
  max-width: 800px;
  padding: 0 0 20px;
  text-align: center;
  width: 100%;

  .description {
    color: ${({ theme }) => theme.green_900};
    line-height: 1.4em;
  }

  .subtitle,
  .title {
    color: ${({ theme }) => theme.white_900};
    margin: 0;
    width: 100%;
  }

  .subtitle {
    margin: 0 0 10px;
  }

  .title {
    margin: 0 0 5px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    text-align: left;
  }
`;

export {
  AsgTitleDiv,
  AsgTitleContainerDiv
}