import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';

import MapBackground from './MapBackground';
import MapList from './MapList';

import { H2 } from '../Typography/styles';

import { ContainerDiv } from '../../layout/Container/styles';
import { MapSection, MapContainerDiv, MapTitleDiv } from './styles';

import { IMap } from './interfaces';

// map
const Map: FC<IMap> = ({ lang }) => {
  const [data, setData] = useState<any>(null);
  const [current, setCurrent] = useState<any>(null); // current
  const [activeTitleModal, setActiveTitleModal] = useState<string | null>(null); // Estado do título modal ativo
  const [activeItemModal, setActiveItemModal] = useState<any>(null); // Estado do item modal ativo

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/map-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [lang]);

  // Função para abrir um título modal
  const openTitleModal = (title_modal: string,) => {
    setActiveItemModal(null); // Fecha qualquer modal de item
    setActiveTitleModal(title_modal); // Abre o modal de título
  };

  // Função para abrir um item modal
  const openItemModal = (item: any) => {
    setActiveTitleModal(null); // Fecha qualquer modal de título
    setActiveItemModal(item); // Abre o modal de item
  };

  return (
    <MapSection id="nossos-ativos">
      <MapBackground lang={lang} />

      <ContainerDiv>
        <MapTitleDiv>
          <H2 className="title">{data?.title}</H2>
        </MapTitleDiv>

        <MapContainerDiv>
          {Array.isArray(data?.items) && data?.items.map((list: any, index: number) =>
            <MapList
              {...list}
              current={current}
              setCurrent={setCurrent}
              activeTitleModal={activeTitleModal}
              setActiveTitleModal={openTitleModal}
              activeItemModal={activeItemModal}
              setActiveItemModal={openItemModal}
              key={index} />)}
        </MapContainerDiv>
      </ContainerDiv>
    </MapSection>
  );
};

export default Map;