import styled from "styled-components";

const NewsSection = styled.section`
  background-color: ${({ theme }) => theme.green_900};
  float: none;
  margin: 0;
  padding: 0 0 70px;
  position: relative;
  width: 100%;
  vertical-align: top;

  .slick {
    &-list,
    &-track { 
      touch-action: pan-y;
    }

    &-list {
      float: left;
      padding: 0;
      overflow: unset;
      position: relative;
      width: 100%;
    }

    &-slide {
      display: flex;

      > div {
        display: flex;
        float: left;
        position: relative;
        width: 100%;
      }
    }

    &-slider {
      float: left;
      padding: 20px 0 80px;
      position: relative;
      width: 100%;

      /* Small Devices, Tablets */
      @media only screen and (max-width : 768px) {
        padding: 20px 20px 80px;
      }
    }

    &-track {
      display: flex;
      margin: 0 auto;
      position: relative;
    }
  }
`;

const NewsTitleDiv = styled.div`
  padding: 55px 0 30px;
  width: 100%;

  .title {
    color: ${({ theme }) => theme.white_900};
    margin: 0;
    text-align: center;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .title {
      padding: 0 20px;
      text-align: left;
    }
  }
`;

export {
  NewsSection,
  NewsTitleDiv
}