import styled from "styled-components";
import ArrowDown from "../../assets/icons/table-filter-arrow.svg";

const AgendamentosDiv = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.white_800};

  header {
    .links-wrapper {
      padding: 26px 0;
      position: relative;

      .link-redirect {
        display: none;
      }

      .link-return {
        position: relative;
        bottom: 0;
        right: 0;
      }

      @media only screen and (max-width : 1024px) {
        padding: 16px 0;
      }

      @media only screen and (max-width : 768px) {
        margin-right: 40px;
        gap: 10px;
      }
    }

    @media only screen and (max-width : 768px) {
      &:has(div):has(.links-wrapper) {
        height: 55px;
  
        &[data-open="true"] {
          &:before {
            height: 0;
          }
        }
    
        &[data-active="true"] {
          &:before {
            height: 60px;
          }
        }
      }
    }
  }

  .pageInfo {
    &::before {
      content: none;
    }
  }

  .page--title {
    color: ${({ theme }) => theme.green_900};
  }

  .page--subtitle {
    color: ${({ theme }) => theme.black_300};
  }

  .page--text {
    margin-top: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.black_300};
    text-decoration: none;
  }
`;

const AgendamentosForm = styled.form`
  max-width: 855px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: column;

  .add-companion {
    margin-bottom: 62px;
  }

  .captcha {
    margin-bottom: 19px;
    align-self: center;
  }

  .loading {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    &-spinner {
      width: 100px;
      height: 100px;
      border: 10px dotted #009265;
      border-radius: 100%;
      animation: rotate 3s linear infinite;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 920px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const FormFieldGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .react-datepicker-wrapper {
    width: fit-content;
  }

  .react-datepicker__tab-loop {
    width: fit-content;
  }

  .field-error {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.6px;
    color: ${({ theme }) => theme.red_900};
    position: absolute;
    left: 0;
    top: 100%;
    margin: 5px 0;
  }

  &.companion-div {
    margin-bottom: 0;

    div[class^='companion-form'] {
      display: flex;
      gap: 20px;

      @media only screen and (max-width : 920px) {
        flex-direction: column;
        gap: 0;
    
        .datepicker-wrapper {
          display: flex;
    
          .react-datepicker-wrapper {
            flex: 1;
          }
        }
      }
    }
  }
`;

const FormFieldGroupTitle = styled.h4`
  margin: 0;
  margin-bottom: 11px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.green_900};
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .required-fields {
    font-weight: 600;
    font-size: 12px;
    line-height: 19.2px;
    color: ${({ theme }) => theme.black_300};

    &:before {
      content: "*";
      margin-right: 3px;
      color: red;
    }
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const FormFieldInline = styled.div`
  display: flex;
  gap: 20px;

  @media only screen and (max-width: 920px) {
    flex-direction: column;
    gap: 0;

    .datepicker-wrapper {
      display: flex;
      margin-bottom: 20px;

      .react-datepicker-wrapper {
        flex: 1;
      }
    }
  }

  &:has(.react-datepicker__tab-loop) {
    .react-datepicker__input-container {
      &:before {
        font-size: 12px;
        top: 6px;
        transform: translateY(0);
      }

      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .react-datepicker__input-container {
    &:has(.pt) {
      &:before {
        content: 'Data';
      }
    }

    &:has(.eng) {
      &:before {
        content: 'Date';
      }
    }

    &:not(:has(input[value=""])) {
      &:before {
        font-size: 12px;
        top: 6px;
        transform: translateY(0);
      }
    }

    &:before {
      content: '';
      font-weight: 600;
      font-size: 16px;
      color: ${({ theme }) => theme.blue_300};
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      transition: all ease-in-out 0.4s;
      z-index: 1;
    }

    input {
      width: 355px;
      height: 48px;
      padding: 18px 20px 0;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 17.6px;
      color: ${({ theme }) => theme.black_300};
      border: 1px solid ${({ theme }) => theme.white_700};
      border-radius: 5px;
      outline: none;
      cursor: pointer;

      @media only screen and (max-width : 1024px) {
        width: 100%;
      }
    }

    &:after {
      content: url(${ArrowDown});
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
`

const ResponseMessageDiv = styled.div`
  width: 100%;
  margin-bottom: 100px;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 18px;
  line-height: 28.8px;
  color: ${({ theme }) => theme.green_900};
  display: none;
  justify-content: center;
`

export {
  AgendamentosDiv,
  AgendamentosForm,
  FormFieldGroup,
  FormFieldGroupTitle,
  FormFieldInline,
  ResponseMessageDiv
}