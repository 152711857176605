import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MapItemModalList from './MapItemModalList';

import { Text } from '../../Typography/styles';
import { MapItemModalDiv, MapItemModalHeaderDiv, MapItemModalSubtitleDiv } from './styles';

import { items } from './items';

// map item modal
const MapItemModal: FC<any> = ({
  item,
  onClose
}) => {
  const { t } = useTranslation();

  // render
  return (
    <MapItemModalDiv className="map-item-modal">
      <MapItemModalHeaderDiv>
        <Text className="title">{item?.name}</Text>

        <button className="button" onClick={onClose}>
          <span className="icon icon-times"></span>
        </button>
      </MapItemModalHeaderDiv>

      <MapItemModalList item={item} items={items} />
    </MapItemModalDiv>
  );
};

export default MapItemModal;