import React, { FC } from 'react';

import OurOperationDescription from '../OurOperationDescription';
import OurOperationVideo from '../OurOperationVideo';

import { IOurOperationInfo } from './interfaces';

import { ContainerDiv } from '../../../layout/Container/styles';
import { OurOperationInfoDiv } from './styles';

// our operation info
const OurOperationInfo: FC<IOurOperationInfo> = ({
  description,
  lang,
  setOpenModal,
  title,
  video
}) => {
  // render
  return (
    <OurOperationInfoDiv>
      <ContainerDiv>
        <OurOperationDescription
          description={description}
          lang={lang}
          title={title} />

        <OurOperationVideo 
          {...video}
          setOpenModal={setOpenModal} />
      </ContainerDiv>
    </OurOperationInfoDiv>
  );
};

export default OurOperationInfo;