import styled from "styled-components";

const NavbarDiv = styled.div<any>`
  float: right;
  text-align: right;
  width: calc(100% - 250px);

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    background-color: ${({ theme }) => theme.green_900};
    align-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    right: -100%;
    height: 100vh;
    max-width: 375px;
    pointer-events: none;
    position: fixed;
    transition: all 500ms cubic-bezier(0.4,0.35,0.25,1);
    top: 0;
    width: 100%;

    &[data-open="true"] {
      pointer-events: auto;
      right: 0;
      top: 0;
    }
  }
`;

const NavbarMenuDiv = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0;
  text-align: right;

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    order: 1;
    padding-top: 70px;
  }
`;

const NavbarMenuTopDiv = styled.div`
  float: right;
  padding: 15px 0 20px;
  width: 100%;

  > .link-nav {
    margin: 2px 40px 0;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    order: 2;
    padding: 20px;
    text-align: left;

    > .link-nav {
      color: ${({ theme }) => theme.black_700};
      font-size: 14px;
      font-weight: 500;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }
`;

export {
  NavbarDiv,
  NavbarMenuDiv,
  NavbarMenuTopDiv
}
