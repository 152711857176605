import React, { memo, FC, useCallback } from 'react';

import { IWebdoorItem } from './interfaces';

import { WebdoorItemDiv } from './styles';

const { PUBLIC_URL } = process.env;

// Webdoor item
const WebdoorItem: FC<IWebdoorItem> = ({
  animate,
  active,
  dir,
  drag,
  item,
  last,
}) => {
  // on drag
  const onDrag = useCallback(() => {
    if (typeof drag === 'function') return drag();

    return {};
  }, [ drag ]);

  // render
  return (
    <WebdoorItemDiv
      {...onDrag()}
      data-animate={animate}
      data-active={active}
      data-last={last}
      data-dir={dir}>
        <img src={`${PUBLIC_URL}${item?.image}`} alt="webdoor" />
    </WebdoorItemDiv>
  );
};

export default memo(WebdoorItem);