import styled from "styled-components";

const WebdoorItemDiv = styled.div`
  cursor: pointer;
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: auto;
  width: 100%;
  top: 0;
  will-change: transform;
  user-select: auto;

  img {
    float: left;
    height: 100%;
    object-fit: cover;
    position: relative;
    pointer-events: none;
    width: 100% !important;
    vertical-align: top;
  }

  &:after {
    background: rgba(0, 0, 0, 0.55);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: auto;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  &[data-active="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-in-right 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-in-left 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }
  }

  &[data-last="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-out-left 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-out-right 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    pointer-events: auto;
  }
`;

export {
  WebdoorItemDiv,
}