import { ILangItem } from "./interfaces";

// langs
export const langs: ILangItem[] = [
  {
    lang: 'pt',
    name: 'por',
  }, {
    lang: 'eng',
    name: 'eng'
  }
];