import styled from "styled-components";
import checkbox from './checkbox.png';

const CheckboxLabel = styled.label`
  padding-left: 30px;
  margin-bottom: 30px;
  display: flex;
  cursor: pointer;
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;

  &:checked + p:after {
    opacity: 1;
    visibility: visible;
  }
`

const CheckboxText = styled.p`
  font-size: 12px;
  line-height: 19px;
  font-weight: normal;
  color: #FFFFFF;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  pointer-events: none;

  .checkbox--text--link {
    color: #A1D64A;
    pointer-events: all;
  }

  &:before,
  &:after {
    content: "";
    height: 20px;
    width: 20px;
    border: solid 1px #A1D64A;
    position: absolute;
    left: -30px;
    box-sizing: border-box;
  }

  &:before {
    border-radius: 5px;
    left: -30px;
    display: block;
  }

  &:after {
    background: url(${checkbox}) no-repeat center;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    transition: opacity ease-in-out 0.2s, visibility ease-in-out 0.2s;
  }
`

export {
  CheckboxLabel,
  CheckboxInput,
  CheckboxText
}
