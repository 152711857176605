import React, { createContext, FC, useState, useCallback } from 'react';
import { ThemeProvider as StyleProvider } from "styled-components";

import { IThemeContext, IThemeProvider } from './interfaces';

// theme context
const ThemeContext: any = createContext({} as IThemeContext);

// theme provider
const ThemeProvider: FC<IThemeProvider> = ({ children }) => {  
  const [ theme, set ]: any = useState({
    blue_200: '#A2E2F0',
    blue_300: '#068CA0',
    blue_400: '#006D7D',
    blue_500: '#00B6D1',
    black: '#000000',
    black_900: '#222222',
    black_700: '#DADADA',
    black_300: '#434343',
    black_100: '#EDEDED',
    green_950: '#0D4333',
    green_900: '#215B4A',
    green_850: '#005B68',
    green_800: '#00363E',
    green_750: '#004853',
    green_700: '#A1D64A',
    green_600: '#016776',
    green_400: '#8DBF3C',
    green_300: '#4CC084',
    green_200: '#002F35',
    green_100: '#1C4D3E',
    red_900: '#DA2C2C',
    white_900: '#FFFFFF',
    white_800: '#F3F3F3',
    white_700: '#CFCFCF',
    white_100: '#D9D9D9'
  });

  // set
  const setColors = useCallback(
    (main: string, second: string) => set({ main, second }), [ set ]);

  // render
  return (
    <StyleProvider theme={theme}>
      <ThemeContext.Provider
        value={{
          theme,
          setColors
        }}>
        {children}
      </ThemeContext.Provider>
    </StyleProvider>
  );
};

export { ThemeContext, ThemeProvider };
export default ThemeProvider;