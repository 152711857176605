const items = [{
  href: 'https://www.linkedin.com/company/3rpetroleum',
  icon: 'icon-linkedin'
}, {
  href: 'https://www.youtube.com/channel/UCsdZJ7HIslixbMZlB7S6IKw',
  icon: 'icon-youtube'
}];

export {
  items
}