import React, { FC } from 'react';

import { MapBackgroundDiv } from './styles';

import { IMapBackground } from './interfaces';

const { PUBLIC_URL } = process.env;

// map background
const MapBackground: FC<IMapBackground> = ({
  lang
}) => {
  return (
    <MapBackgroundDiv>
      <div className="background">
        <img
          className="background--image"
          src={`${PUBLIC_URL}/images/map-${lang.lang}.png`}
          alt="bg" />
      </div>

      <img className="image" src={`${PUBLIC_URL}/images/bg-map.png`} alt="bg" />
    </MapBackgroundDiv>
  );
};

export default MapBackground;