import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkScroll } from "react-scroll";

import { NavbarMenuNav } from './styles';

import { INavbarMenu } from './interfaces';

// navbar menu
const NavbarMenu: FC<INavbarMenu> = ({ 
  items
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <NavbarMenuNav>
      {items && items.map(({ to, text }: any, index: number) => 
        <LinkScroll
          className="navbar-menu--link"
          to={to}
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          tabIndex={index}
          key={index}>
          <span className="text">{t(text)}</span>
        </LinkScroll>)}
    </NavbarMenuNav>
  );
};

export default NavbarMenu;