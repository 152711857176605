import React, { FC } from 'react';

import { SubTitle } from '../../Typography/styles';

import { ContactInfoDiv, ContactInfoItemDiv } from './styles';
import { IContactInfo } from './interfaces';

// contact info
const ContactInfo: FC<IContactInfo> = ({
  items
}) => {
  // render
  return (
    <ContactInfoDiv>
      {items && items.map((item: any, index: number) =>
        <ContactInfoItemDiv data-type={item.type} key={index}>
          <SubTitle className="label">{item.title}</SubTitle>

          {<>
              {(item.type !== 1) && 
                <SubTitle className="description">{item.description}</SubTitle>}

              {item.type === 3 && 
                <>
                  <span className="number">{item.number}</span>
                  <SubTitle className="description">{item.description_con}</SubTitle>
                </>}

              {item.type === 5 && 
                <>
                  <span className="number">{item.number}</span>
                </>}

              {item.link ? ( 
                <a 
                  className="link"
                  href={item.link.href}
                  target="_blank"
                  rel="noreferrer">{item.link.text}</a>
              ): ""}

              {item.type === 4 &&
                <SubTitle className="description">{item.description_con}</SubTitle>}
            </>}
        </ContactInfoItemDiv>)}
    </ContactInfoDiv>
  );
};

export default ContactInfo;