import styled from "styled-components";

const WeAreDiv = styled.section`
  float: left;
  width: 100%;
`;

const WeAreContainerDiv = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr; 
  }
`;

export {
  WeAreDiv,
  WeAreContainerDiv
}