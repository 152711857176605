import React, { FC } from 'react';
import DOMPurify from 'dompurify';

import { ContainerMinDiv } from '../../layout/Container/styles';
// import { ButtonDefaultLink } from '../Form/Button/styles';
import { H2, H2Text, H2Title, ParagraphTitle } from '../Typography/styles';
import { MessageDiv, NoticeDiv, ContactInfo } from './styles';

// message
const MessagePrivacidade: FC<any> = ({
  title,
  firstSubtitle,
  secondSubtitle,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  fourthParagraph,
  fifthParagraph,
  sixthParagraph,
  seventhParagraph,
  eighthParagraph,
  ninethParagraph,
  tenthParagraph,
  firstParagraphTitle,
  secondParagraphTitle,
  thirdParagraphTitle,
  fourthParagraphTitle,
  noticeText,
  contactInfo,
  link
}) => {
  const noticeSanitizedData = () => ({
    __html: DOMPurify.sanitize(noticeText)
  });

  const contactSanitizedData = () => ({
    __html: DOMPurify.sanitize(contactInfo)
  });

  return (
    <MessageDiv>
      <ContainerMinDiv>
        <H2 className="message--title">{title}</H2>
        <H2Title className="message--subtitle">{firstSubtitle}</H2Title>
        <H2Text className="message--text">{firstParagraph}</H2Text>
        <H2Text className="message--text">{secondParagraph}</H2Text>

        <H2Title className="message--subtitle">{secondSubtitle}</H2Title>
        <ParagraphTitle className="message--paragraph-title">{firstParagraphTitle}</ParagraphTitle>
        <H2Text className="message--text">{thirdParagraph}</H2Text>
        <H2Text className="message--text">{fourthParagraph}</H2Text>

        <ParagraphTitle className="message--paragraph-title">{secondParagraphTitle}</ParagraphTitle>
        <H2Text className="message--text">{fifthParagraph}</H2Text>

        <ParagraphTitle className="message--paragraph-title">{thirdParagraphTitle}</ParagraphTitle>
        <H2Text className="message--text">{sixthParagraph}</H2Text>
        <H2Text className="message--text">{seventhParagraph}</H2Text>

        <NoticeDiv>
          <p className="notice--text" dangerouslySetInnerHTML={noticeSanitizedData()}></p>
        </NoticeDiv>

        <ParagraphTitle className="message--paragraph-title">{fourthParagraphTitle}</ParagraphTitle>
        <H2Text className="message--text">{eighthParagraph}</H2Text>

        <H2Text className="message--text">{ninethParagraph}</H2Text>

        <ContactInfo>
          <p className="contact-info--text" dangerouslySetInnerHTML={contactSanitizedData()}></p>
        </ContactInfo>

        <H2Text className="message--text">{tenthParagraph}</H2Text>

        {/* <ButtonDefaultLink
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=CFlWcu8Qjkm5PslJeDZgGIlsh2wlY4hCq_cMFKqtw8JUQkMxS0JITk1QVUZLMlZPS1NVVUZTUzRJWS4u"
          target="_blank"
          rel="noreferrer">
          {link}
        </ButtonDefaultLink> */}
      </ContainerMinDiv>
    </MessageDiv>
  );
};

export default MessagePrivacidade;