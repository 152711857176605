import styled from "styled-components";

const NavbarSubMenuDiv = styled.div`
  float: none;
`;

const NavbarSubMenuUl = styled.ul`
  list-style: none;
  margin: 0 30px 0 0;
  padding: 3px 0;

  li {
    float: left;

    > a {
      cursor: pointer;
      float: left;
      padding: 3px 20px;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    li {
      &:before {
        background-color: ${({ theme }) => theme.white_900};
        content: '';
        height: 100%;
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 1px;
      }

      > a {
        padding: 0px 15px;
      }

      &:first-child {
        > a {
          padding-left: 0;
        }
      }

      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }
`;

export {
  NavbarSubMenuDiv,
  NavbarSubMenuUl
}