import styled from "styled-components";

const OurOperationInfoDiv = styled.div`
  width: 100%;

  &:before {
    background-color: ${({ theme }) => theme.black_100};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(50% + 200px);
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    &:before {
      display: none;
    }
  }
`;

export {
  OurOperationInfoDiv
}