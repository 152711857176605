import styled from "styled-components";

const MapItemDiv = styled.div`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.green_850};
  padding: 15px 0;
  width: 100%;

  .map-item {
    &--button {
      background-color: ${({ theme }) => theme.green_200};
      border: none;
      border-radius: 2px;
      cursor: pointer;
      height: calc(100% - 30px);
      outline: none;
      right: 0;
      position: absolute;
      top: 15px;
      width: 20px;

      .icon {
        color: ${({ theme }) => theme.green_700};
        font-size: 9px;
        left: 50%;
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--description,
    &--title,
    &--type {
      float: left;
      margin: 0 0 5px 0;
      width: 100%;  
    }
    
    &--description {
      color: ${({ theme }) => theme.blue_200};
      font-weight: 500;
      margin: 5px 0;
    }

    &--number {
      color: ${({ theme }) => theme.white_900};
      font-size: 15px;
      font-weight: 600;
      left: 50%;
      position: absolute;
      top: -12px;
      text-transform: uppercase;
      transform: translate(-50%, 0%);
    }

    &--title {
      color: ${({ theme }) => theme.green_700};
      font-weight: 500;
      padding: 5px 0 5px 40px;
    }

    &--type {
      color: ${({ theme }) => theme.blue_500};
      font-weight: 600;
    }
  }

  &[data-active="true"] {
    .map-item {
      &--number {
        color: ${({ theme }) => theme.green_700};
      }
    }
  }
`;

const MapItemTitleDiv = styled.div`
  left: 0;
  position: absolute;
  width: auto;

  &[data-active="true"] {
    svg {
      path {
        fill: ${({ theme }) => theme.green_900};
      }
    }
  }
`;

export {
  MapItemDiv,
  MapItemTitleDiv
}