import styled from "styled-components";

const WeAreItemDiv = styled.div`
  align-content: center;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 90px 20% 90px 20px;
  width: 100%;

  .number {
    color: ${({ theme }) => theme.green_900};
    left: -45px;
    opacity: 0.2;
    position: absolute;
    top: 0;
  }

  h1,
  p {
    float: left;
    width: 100%;
    margin: 0;
    color: ${({ theme }) => theme.white_900};
  }

  h1 {
    margin: 0 0 40px;
  }

  p {
    line-height: 1.6em;
  }

  &:before {
    background: inherit;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    width: 50vw;
  }

  &[data-type="1"] {
    background-color: ${({ theme }) => theme.white_900};

    p {
      color: ${({ theme }) => theme.green_900};
      order: 1;
    }

    h1 {
      color: ${({ theme }) => theme.green_900};
      order: 2;
      line-height: 1em;
      margin: 20px 0 0;

      span {
        left: initial;
        line-height: 1em;
        position: relative;
        top: initial;
      }
    }

    &:before {
      right: 0;
    }
  }

  &[data-type="2"] {
    background-color: ${({ theme }) => theme.green_400};

    &:before {
      left: 0;
    }
  }

  &[data-type="3"] {
    background-color: ${({ theme }) => theme.green_300};

    &:before {
      right: 0;
    }
  }

  &[data-type="4"] {
    background-color: ${({ theme }) => theme.blue_300};
    
    &:before {
      left: 0;
    }
  }

  &:nth-child(even) {
    padding-left: 20%;
    padding-right: 20px;

    .number {
      left: -45px;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 90px 40px 60px;

    .number {
      font-size: 175px;
      left: 0;
    }
    
    h1 {
      font-size: 40px;
    }

    &:nth-child(even) {
      padding: 90px 40px 60px;
    }

    &[data-type="2"],
    &[data-type="3"],
    &[data-type="4"] {
      .number {
        left: 0px;
      }

      p {
        margin-top: 30px;
      }

      h1 {
        padding-left: 40px;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 90px 20px 40px;

    &:nth-child(even) {
      padding: 90px 20px 40px;
    }
  }
`;

export {
  WeAreItemDiv,
}