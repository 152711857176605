import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import SelectLang from '../../../components/SelectLang';

import { LangContext } from '../../../providers/LangProvider';

import { HeaderInternaDiv } from './styles';
import { ButtonDefaultLink } from '../../../components/Form/Button/styles';

// header interna
const HeaderInterna: FC<any> = () => {
  const { lang, langs, setLang } = useContext(LangContext);

  // render
  return (
    <HeaderInternaDiv className='links-wrapper'>
      <Link
      target='_blank'
      className="link-redirect"
      to="/agendamento-de-visita">{lang.lang === 'eng' ? 'Visit Schedule' : 'Agendamento de Visita'}</Link>

      <Link
      className="link-return"
      to="/">{lang.lang === 'eng' ? 'back to 3R Petroleum site' : 'voltar ao site 3R Petroleum'}</Link>

      <SelectLang
        lang={lang}
        langs={langs}
        setLang={setLang}
        mobile={false} />
    </HeaderInternaDiv>
  );
};

export default HeaderInterna;