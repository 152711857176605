import React, { FC } from 'react';
import DOMPurify from 'dompurify';

import { ContainerMinDiv } from '../../layout/Container/styles';
import { H2, H2Text, H2Title } from '../Typography/styles';
import { PageInfoDiv } from './styles';

//PageInfo

const PageInfo: FC<any> = ({
    title,
    subtitle,
    text
}) => {

    const manualSanitizedData = (data: string) => ({
        __html: DOMPurify.sanitize(data)
    });

    return (
        <PageInfoDiv>
            <ContainerMinDiv className='pageInfo'>
                <H2 className="page--title">{title}</H2>
                <H2Title className="page--subtitle">{subtitle}</H2Title>

                <H2Text className="page--text" dangerouslySetInnerHTML={manualSanitizedData(text)}></H2Text>
            </ContainerMinDiv>
        </PageInfoDiv>
    )
};

export default PageInfo;
