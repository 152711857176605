import styled from "styled-components";

const MessageDiv = styled.div`
  background-color: ${({ theme }) => theme.green_900};
  padding: 140px 0;
  width: 100%;

  .message {
    &--footer,
    &--title,
    &--subtitle,
    &--text {
      color: ${({ theme }) => theme.white_900};
      margin: 0;
      width: 100%;
    }

    &--footer {
      font-weight: 600;
      margin: 0 0 35px;
    }

    &--subtitle {
      font-weight: 600;
      line-height: 1.4em;
      margin: 0 0 35px;
    }

    &--text {
      margin: 0 0 35px;
      line-height: 1.6em;
      white-space: pre-line;
    }

    &--title {
      color: ${({ theme }) => theme.green_700};
      margin: 0 0 20px;
      line-height: 1.1em;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 90px 0;

    .message {
      &--title {
        font-size: 36px;
        margin: 0 0 40px;
      }

      &--subtitle {
        font-size: 21px;
      }
    }
  }
`;

const ManualDiv = styled.div`
  width: 100%;
  max-width: 855px;
  background: #1D5141;
  border-radius: 8px;
  padding: 10px 17px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .manual--image {
    height: 51px;
    width: 40px;
  }

  .manual--text {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 0;
    margin-left: 20px;
    margin-bottom: 0;
    flex: 1;

    &--link {
      text-decoration: underline;
      color: #A1D64A;
    }
  }
`;

export {
  MessageDiv,
  ManualDiv
}