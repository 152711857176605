import axios from 'axios';
import React, { FC, useState, useEffect } from 'react';
import Slider from "react-slick";

import NewsArrow from './NewsArrow';
import NewsItem from './NewsItem';

import { ContainerDiv } from '../../layout/Container/styles';
import { H2 } from '../Typography/styles';

import { INews } from './interfaces';

import { NewsSection, NewsTitleDiv } from './styles';

// settings
import { settings } from './settings';

// news
const News: FC<INews> = ({ lang }) => {
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/news-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <NewsSection id="noticias">
      <NewsTitleDiv>
        <ContainerDiv>
          <H2 className="title">{data?.title}</H2>
        </ContainerDiv>
      </NewsTitleDiv>

      <ContainerDiv>
        {Array.isArray(data?.items) && <Slider
          {...settings}
          nextArrow={<NewsArrow className="btn-left" icon="icon-arrow-bold" />}
          prevArrow={<NewsArrow className="btn-right" icon="icon-arrow-bold" />}>
          {Array.isArray(data?.items) && data?.items.map((item: any, index: number) =>
            <NewsItem key={index} {...item} />)}
        </Slider>}
      </ContainerDiv>
    </NewsSection>
  );
};

export default News;