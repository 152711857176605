import React, { FC } from 'react';
import { Link as LinkScroll } from "react-scroll";

import { H1, Text } from '../../../Typography/styles';

import { IWebdoorInfoItem } from './interfaces';

import { WebdoorInfoItemDiv } from './styles';

// webdoor info item
const WebdoorInfoItem: FC<IWebdoorInfoItem> = ({
  animate,
  active,
  item
}) => {
  // render
  return (
    <WebdoorInfoItemDiv
      data-animate={animate}
      data-active={active}>
      <H1>{item?.title}</H1>
      <Text>{item?.description}</Text>

      <LinkScroll
        className="webdoor-info-item--link"
        to={item?.to}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        tabIndex={0}>
        <span className="icon icon-arrow-light"></span>
      </LinkScroll>
    </WebdoorInfoItemDiv>
  );
};

export default WebdoorInfoItem;