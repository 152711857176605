import React, { FC } from 'react';

import { Text, H2 } from '../../Typography/styles';
import { OurOperationDescriptionGraphDiv, OurOperationDescriptionDiv, OurOperationDescriptionTitleDiv } from './styles';

import { IOurOperationDescription } from './interfaces';

import { ReactComponent as PTGraph } from '../../../assets/svg/new_graph.svg';
import { ReactComponent as ENGraph } from '../../../assets/svg/new_graph_en.svg';

// our operation description
const OurOperationDescription: FC<IOurOperationDescription> = ({
  description,
  lang,
  title,
}) => {
  // render
  return (
    <OurOperationDescriptionDiv>
      <OurOperationDescriptionTitleDiv data-aos="fade-up">
        <H2 className="title">{title}</H2>
        {/* <Text className="description">{description}</Text> */}
      </OurOperationDescriptionTitleDiv>

      <OurOperationDescriptionGraphDiv data-aos="fade-up">
        {lang.lang === 'eng' ? <ENGraph /> : <PTGraph />}
      </OurOperationDescriptionGraphDiv>
    </OurOperationDescriptionDiv>
  );
};

export default OurOperationDescription;