import styled from "styled-components";

const SelectItemButton = styled.button`
  background-color: ${({ theme }) => theme.white_900};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.white_700};
  float: left;
  height: 48px;
  line-height: 1em;
  padding: 10px 20px;
  position: relative;
  text-align: left;
  width: 100%;
  user-select: none;
  vertical-align: top;

  .text {
    color: ${({ theme }) => theme.black_300};
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    float: left;
    margin: 0;
    line-height: 1em;
    transition: all 400ms ease-in;
    width: 100%;
    white-space: nowrap;
  }

  &[data-active="true"] {
    .text {
      color: ${({ theme }) => theme.white_900};
      font-weight: 400;
    }

    &:before {
      background-color: ${({ theme }) => theme.green_850};
      content: '';
      height: 100%;
      left: 0;
      opacity: 1;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &:hover {
    .text {
      color: ${({ theme }) => theme.blue_300};
      font-weight: 400;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export {
  SelectItemButton
}