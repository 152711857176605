import React, { FC } from 'react';

import { IInputFormLabel } from './interfaces';
import { InputFormLabelDiv } from './styles';

// input form label
const InputFormLabel: FC<IInputFormLabel> = ({
  children
}) => {
  return (
    <InputFormLabelDiv>
      {children}
    </InputFormLabelDiv>
  );
};

export default InputFormLabel;