import styled from "styled-components";

const OurOperationDescriptionGraphDiv = styled.div`
  margin: 40px 0 0;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    overflow: auto;
    overflow-x: scroll;

    svg {
      margin: 0 20px;
      width: 730px;
    }
  }
`;

const OurOperationDescriptionTitleDiv = styled.div`
  width: 100%;

  .description,
  .title {
    margin: 0;
    width: 100%;
  }

  .description {
    color: ${({ theme }) => theme.black_300};
    margin: 30px 0 0;
    max-width: 540px;
    line-height: 1.6em;
  }

  .title {
    color: ${({ theme }) => theme.green_900};
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0 35px;

    .description {
      max-width: none;
    }
  }
`;

const OurOperationDescriptionDiv = styled.div`
  margin: 135px 0 110px;
  padding: 0 40px 0 0;
  width: calc(50% + 70px);

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 0;
    padding: 60px 0 50px;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    &:before {
      background-color: ${({ theme }) => theme.black_100};
      content: '';
      height: calc(100% + 150px);
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;

export {
  OurOperationDescriptionGraphDiv,
  OurOperationDescriptionDiv,
  OurOperationDescriptionTitleDiv
}