import React, { FC } from "react";

import { LoaderContainerDiv, LoaderDiv } from "./styles";

import { ReactComponent as LoaderSvg } from '../../assets/svg/loader.svg';

import { ILoader } from "./interfaces";

// loader
const Loader: FC<ILoader> = ({ animate }) => {
  // render
  return (
    <LoaderDiv data-animate={animate}>
      <LoaderContainerDiv data-animate={animate}>
        <LoaderSvg />
      </LoaderContainerDiv>
    </LoaderDiv>
  )
}

export default Loader;
