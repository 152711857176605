import React, { FC, useState } from "react";

import Select from "react-dropdown-select";
import { ButtonDefaultLink } from "../../Form/Button/styles";
import { FilterBottomMessage, TableFilterDiv } from "./styles";

const TableFilter: FC<any> = ({
  products,
  years,
  months,
  functionClick,
  messageBottomProduct,
  messageBottomUnity
}) => {
  
  const [values, setValues] = useState<any>();

  return (
    <>
      <TableFilterDiv>
        <Select
          className="dropdown-filter products"
          options={products}
          labelField="nome"
          valueField="id"
          onChange={(values) => setValues(values)}
          values={[]}
          placeholder="Produto"
          searchable={false}
        />

        <Select
          className="dropdown-filter years"
          options={years}
          labelField="nome"
          valueField="ano"
          onChange={(values) => setValues(values)}
          values={[]}
          placeholder="Ano"
          searchable={false}
        />

        <Select
          className="dropdown-filter months"
          options={months}
          labelField="nome"
          valueField="mes"
          onChange={(values) => setValues(values)}
          values={[]}
          placeholder="Mês"
          searchable={false}
        />

        <ButtonDefaultLink
          className="search-button"
          rel="noopener noreferrer"
          onClick={functionClick}
        >
          Pesquisar
        </ButtonDefaultLink>
      </TableFilterDiv>

      {messageBottomProduct != null &&
      <FilterBottomMessage>
        <span>Preços de <strong>{messageBottomProduct}</strong> sem tributos, à vista, por vigência <span id="filter-bottom-unity">({messageBottomUnity})</span></span>
      </FilterBottomMessage>
      }
    </>
  );
};

export default TableFilter;
