import React, { FC, useCallback } from 'react';
import clamp from 'lodash/clamp';
import { useGesture } from 'react-use-gesture';

import SliderBase from '../../Slider/Base';
import WebdoorInfo from '../WebdoorInfo';
import WebdoorItem from '../WebdoorItem';

import { IWebdoorContent } from './interfaces';

import { WebdoorContentDiv } from './styles';

// Webdoor content
const WebdoorContent: FC<IWebdoorContent> = ({
  animate,
  current,
  items,
  direction,
  last,
  setCurrent
}) => {
  // drag on gesture
  const dragOnGesture = useCallback(({
    down,
    movement: [mx],
    direction: [xDir],
    distance,
    cancel
  }: any) => {
    if (down && distance > 15) {
      const value = clamp(current + (xDir > 0 ? 1 : -1), 0, items.length - 1);
      cancel(setCurrent(value));
    }
  }, [ current, items, setCurrent ]);

  // drag
  const drag = useGesture({
    onDrag: dragOnGesture
  });

  // render
  return (
    <>
      <WebdoorContentDiv>
        {items && items.map((item: any, index: number) => (
          <WebdoorItem
            animate={animate}
            active={current === index}
            drag={drag}
            dir={direction}
            item={item}
            last={last === index}
            key={index} />))}
      </WebdoorContentDiv>

      <WebdoorInfo
        animate={animate}
        current={current}
        last={last}
        items={items} />
    </>
  )
};

export default SliderBase(WebdoorContent);
