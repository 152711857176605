import React, { FC } from 'react';

import { H2, H2Text, Text } from '../../Typography/styles';
import { AsgTitleDiv, AsgTitleContainerDiv } from './styles';

import { IAsgTitle } from './interfaces';

// ags title
const AsgTitle: FC<IAsgTitle> = ({
  title,
  subtitle,
  description
}) => {
  // render
  return (
    <AsgTitleDiv>
      <AsgTitleContainerDiv>
        <H2 className="title" data-aos="fade-up">{title}</H2>
        <H2Text className="subtitle" data-aos="fade-up">{subtitle}</H2Text>
        <Text className="description" data-aos="fade-up">{description}</Text>
      </AsgTitleContainerDiv>
    </AsgTitleDiv>
  );
};

export default AsgTitle;