import React, { FC } from 'react';

import { IListItem } from './interfaces';

import { H2, Text } from '../../Typography/styles';
import { ListItemDiv } from './styles';

// list item
const ListItem: FC<IListItem> = ({
  body,
  list,
  title,
  type,
}) => {
  return (
    <ListItemDiv data-type={type}>
      <H2>{title}</H2>

      {type === 1 && <Text>{body}</Text>}
      
        {type === 2 && 
          <ul>
            {Array.isArray(list) && list.map((item: string, index: number) =>
              <li key={index}>
                <Text>{item}</Text>
              </li>
            )}
          </ul>}
    </ListItemDiv>
  );
};

export default ListItem;