import styled from "styled-components";

const ProdutosDiv = styled.div`
  width: 100%;

  header {
    .links-wrapper {
      padding: 31px 0;
      position: relative;

      .link-redirect {
        display: none;
      }

      .link-return {
        position: relative;
        bottom: 0;
        right: 0;
        
        @media (max-width: 1024px) {
          max-width: none;
        }
      }

      .lang-toggle {
        display: none;
      }

      @media only screen and (max-width : 1024px) {
        padding: 21px 0;
      }

      @media only screen and (max-width : 768px) {
        margin-right: 40px;
        gap: 10px;
      }
    }

    @media only screen and (max-width : 768px) {
      &:has(div):has(.links-wrapper) {
        height: 55px;
  
        &[data-open="true"] {
          &:before {
            height: 0;
          }
        }
    
        &[data-active="true"] {
          &:before {
            height: 60px;
          }
        }
      }
    }
  }

  .page {
    &--title,
    &--subtitle,
    &--text {
      color: ${({ theme }) => theme.green_900};
      margin: 0;
      width: 100%;
    }
  }

  .page--text {
    margin-top: 20px;
    font-weight: 600;
    text-decoration: none;
  }
`;

export {
  ProdutosDiv
}