import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import axios from 'axios';

import InputForm from '../../Form/Input/InputForm';
import InputGroup from '../../Form/Input/InputGroup';
import InputFormLabel from '../../Form/Input/InputForm/InputFormLabel';
import Select from '../../Form/Select';

import useForm from '../../../hooks/useForm';

import { ButtonDefault } from '../../Form/Button/styles';
import { ContactFormDiv, ContactFormFooterDiv, ContactFormForm } from './styles';
import { Text } from '../../Typography/styles';

import { subjects as SubjectItems } from './subjects';

import { IContactForm } from './interfaces';

// url
const { REACT_APP_CONTACT_FORM }: any = process.env;

// contact form
const ContactForm: FC<IContactForm> = ({
  title
}) => {
  const [ subjects ] = useState<any>([...SubjectItems]);
  const [ error, setError ] = useState<boolean>(false);
  const [ selectSubject, setSelectSubject ]: any = useState<any>({ value: '', label: '' });

  const { t }: any = useTranslation();
  
  const { typesForm } = useForm();
  
  // select subject
  const onSetSubject = (value: string) => setSelectSubject(value);
  
  // initial
  const initialValues: any = {
    sobrenome: '',
    nome: '',
    email: '',
    assunto: '',
    mensagem: ''
  };

  // render
  return (
    <ContactFormDiv>
      <Text className="contact-form--title">{title}</Text>
      
      <Formik
        // init
        initialValues={initialValues}
        initialStatus={{ success: false }}
        // validate
        validate={(values: any) => {
          const errors: any = typesForm({ ...values, selectSubject }, 'contact');
          setError(Object.keys(errors).length > 0);

          return errors;
        }}
        // on submit
        onSubmit={(values, {
          setSubmitting,
          setStatus,
          resetForm
        }) => {
          setStatus({ success: false });

          const params = values;
          params.assunto = selectSubject.value;

          axios.post(REACT_APP_CONTACT_FORM, {
            ...params
          }).then(e => {
            resetForm(initialValues);
            setSelectSubject({});
            setStatus({ success: true });
            return e;
          }).catch(e => setStatus({ error: true }));

          setSubmitting(false);
        }}>
        {({
          values,
          errors,
          status,
          isSubmitting,
        }) => (
          <>
            {(status.error === false || status.success === false) &&
              <ContactFormForm
                autoComplete="off"
                data-submitting={isSubmitting}>
                <InputForm
                  label={t('form.label.name')}
                  name="nome"
                  type="text"
                  error={errors.nome}
                  empty={!values?.nome}
                  required={true} />

                <InputForm
                  label={t('form.label.lastname')}
                  name="sobrenome"
                  type="text"
                  error={errors.sobrenome}
                  empty={!values?.sobrenome}
                  required={true} />

                <InputForm
                  label={t('form.label.email')}
                  name="email"
                  type="email"
                  error={errors.email}
                  empty={!values?.email}
                  required={true} />

                <InputGroup>
                  <InputFormLabel
                    data-type="select"
                    data-error={errors.assunto}>
                    <Select
                      defaultText={t('form.label.subject')}
                      options={subjects}
                      handleChange={onSetSubject}
                      selectedOption={selectSubject} />

                    <input name="assunto" type="hidden" required={true} />
                  </InputFormLabel>
                </InputGroup>

                <InputGroup>
                  <InputForm
                    empty={values?.mensagem}
                    label={t('form.label.your_message')}
                    name="mensagem"
                    type="textarea"
                    error={errors.mensagem}
                    required={true} />
                </InputGroup>

                {!status.success &&
                  <ContactFormFooterDiv>
                    <ButtonDefault
                      className="form-contact--button"
                      type="submit"
                      disabled={isSubmitting}>{t('form.send')}</ButtonDefault>
                  </ContactFormFooterDiv>}
              </ContactFormForm>}

              {status.success === true && <h1>sucesso</h1>}
              {(status.error === true || Object.keys(error).length > 0) && <h1>erro</h1>}
          </>
        )}
      </Formik>
    </ContactFormDiv>
  );
};

export default ContactForm;