import React, { FC, useRef, useState } from 'react';

import useOutsideRef from "../../hooks/useOutsideRef";

import { ILangItem } from '../../providers/LangProvider/interfaces';

import { SelectLangDiv, SelectLangListUl } from './styles';

// select lang
const SelectLang: FC<any> = ({
  lang,
  langs,
  setLang,
  mobile = true
}) => {
  const selectRef = useRef(null);
  const [ active, setActive ] = useState<boolean>(false);

  useOutsideRef(selectRef, () => setActive(false));

  // render
  return (
    <SelectLangDiv
      className="lang-toggle"
      data-active={active}
      ref={selectRef}
      data-mobile={mobile}>
      <button
        className="select-lang--item"
        onClick={() => setActive(true)}>
          {lang.name}
          <span className="icon icon-arrow-bold"></span>
      </button>

      <SelectLangListUl
        data-active={active}
        data-mobile={mobile}>
        {langs && langs.map((item: ILangItem, index: number) =>
          <li
            className="select-lang--list--item"
            data-active={lang.lang === item.lang}
            key={index}>
            <button
              className="select-lang--item"
              onClick={() => setLang(item.name)}>{item.name}</button>
          </li>)}
      </SelectLangListUl>
    </SelectLangDiv>
  );
};

export default SelectLang;

