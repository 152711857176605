import styled from "styled-components";

const InternaDiv = styled.div`
  width: 100%;

  header + div {
    min-height: calc(100vh - 100px);
  }
`;

export {
  InternaDiv
}