import styled from "styled-components";

const ListItemDiv = styled.div`
  padding: 0 60px;
  width: auto;

  h2,
  ul {
    width: 100%;
  }

  h2 {
    color: ${({ theme }) => theme.green_300};
    font-weight: 400;
    text-align: center;

    &:after,
    &:before {
      bottom: -20px;
      content: '';
      height: 6px;
      position: absolute;
    }

    &:before {
      background-color: ${({ theme }) => theme.green_300};
      left: 0;
      width: 20%;
    }

    &:after {
      background-color: ${({ theme }) => theme.green_700};
      left: 20%;
      width: 80%;
    }
  }

  p {
    color: ${({ theme }) => theme.black_300};
    line-height: 1.6em;
    margin-top: 0;
  }

  ul {
    margin: 0;
    column-count: 2;
    padding: 0;
    width: 100%;

    li {
      padding: 0 30px;
      width: 100%;

      &:before {
        background-color: ${({ theme }) => theme.blue_300};
        content: '';
        height: 6px;
        left: 0;
        position: absolute;
        top: 9px;
        width: 15px;
      }
    }
  }

  &[data-type="1"] {
    width: 50%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0 35px;

    ul {
      column-count: 1;

      li {
        padding-right: 0;
      }
    }
  
    &[data-type="1"] {
      width: 100%;
    }
  }
`;

export {
  ListItemDiv
}