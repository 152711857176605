import React, { FC } from 'react';
import { CheckboxLabel, CheckboxInput, CheckboxText } from './styles';

const Checkbox:FC<any> = ({ text, onChange }) => {
  return (
    <CheckboxLabel>
      <CheckboxInput onChange={(event) => onChange(event.target.checked)} type="checkbox" name="checkbox" />
      <CheckboxText className="checkbox--text" dangerouslySetInnerHTML={{ __html: text }}></CheckboxText>
    </CheckboxLabel>
  )
}

export default Checkbox;
