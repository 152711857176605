import axios from 'axios';
import React, { createContext, FC, useEffect, useState, useContext } from 'react';

import { LangContext } from '../LangProvider';
import { IMainContext, IMainProvider } from './interfaces';

// main context
const MainContext = createContext({} as IMainContext);

// main provider
const MainProvider: FC<IMainProvider> = ({
  children,
}) => {
  const { lang } = useContext(LangContext);
  const [ data, setData ] = useState<any | null>({});

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`/data/main-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <MainContext.Provider
      value={{
        ...data
      }}>
      {children}
    </MainContext.Provider>
  );
};

export { MainProvider, MainContext };
export default MainProvider;