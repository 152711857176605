import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectItem } from './interfaces';

import { SelectItemButton } from './styles';

// select item
const SelectItem: FC<ISelectItem> = ({
  item,
  onSelectOption,
  selectedOption,
  translate
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <SelectItemButton
      type="button"
      onClick={() => onSelectOption(item)}
      data-active={selectedOption?.value === item?.value}>
      {item && <p className="text">{translate === false ? item?.label
        : t(`form.select.${item?.label.toLowerCase().replace(/ /g, '_')}`)}</p>}
    </SelectItemButton>
  );
};

export default SelectItem;