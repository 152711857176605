import React, { FC, useCallback } from 'react';
import { useTranslation } from "react-i18next";

import { LinkDefault, LinkClear } from '../../Typography/styles';

import { NavbarSubMenuDiv, NavbarSubMenuUl } from './styles';

import { INavbarSubMenu } from './interfaces';

// navbar sub menu
const NavbarSubMenu: FC<INavbarSubMenu> = ({
  items
}) => {
  const { t } = useTranslation();

  // get item
  const getItem = useCallback((item: any) => {
    if (item.type === 2) {
      return <LinkDefault
        href={item?.link}
        rel="noreferrer"
        target="_blank">{t(item?.text)}
      </LinkDefault>;
    }

    return <LinkClear
      to={item?.to}
      spy={true}
      smooth={true}
      offset={0}
      duration={500}
      tabIndex={0}>{t(item?.text)}</LinkClear>;
  }, [ t ]);

  // render
  return (
    <NavbarSubMenuDiv>
      <NavbarSubMenuUl>
        {items && items.map((item: any, index: number) => 
          <li key={index}>{getItem(item)}</li>)}
      </NavbarSubMenuUl>
    </NavbarSubMenuDiv>
  );
};

export default NavbarSubMenu;