import styled from "styled-components";

const TableDiv = styled.div`
    width: 100%;
    overflow-x: auto;

    @media (max-width: 856px) {
        padding: 0 20px 20px;
    }
`;

const TableContainer = styled.table`
    width: 855px;
    margin: 0 auto;
    border-spacing: 0;
    display: flex;
    flex-direction: column;

    tbody {
        tr {
            padding: 11px 0;
            border-bottom: 1px solid #DADADA;

            &:first-child {
                padding-top: 17px;
            }
        }
    }

    tr {
        width: 100%;
        display: flex;

        th, td {
            width: 22%;
            text-align: start;

            &:nth-child(2) {
                width: 34%;
            }
        }
    
        th {
            padding-bottom: 22px;
            border-bottom: 1px solid #A1D64A;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #215B4A;
        }
    
        td {
            font-weight: 600;
            font-size: 16px;
            line-height: 17.5px;
            color: #068CA0;
        }
    }
`;

export {
    TableDiv,
    TableContainer
}