import styled from "styled-components";

const InternaDiv = styled.div`
  width: 100%;

  header + div {
    min-height: calc(100vh - 100px);
  }

  header {
    .links-wrapper {
      padding: 26px 0;
      position: relative;

      .link-redirect {
        display: none;
      }

      .link-return {
        position: relative;
        bottom: 0;
        right: 0;
      }

      @media only screen and (max-width : 1024px) {
        padding: 16px 0;
      }

      @media only screen and (max-width : 768px) {
        margin-right: 40px;
        gap: 10px;
      }
    }

    @media only screen and (max-width : 768px) {
      &:has(div):has(.links-wrapper) {
        height: 55px;
  
        &[data-open="true"] {
          &:before {
            height: 0;
          }
        }
    
        &[data-active="true"] {
          &:before {
            height: 60px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .message {
      padding: 141px 0;
    }
  }
`;

export {
  InternaDiv
}