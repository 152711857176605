// settings
export const settings = {
  adaptiveHeight: true,
  className: "slider variable-width",
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [{
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  }]
};