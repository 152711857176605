import { useState, useEffect, useCallback } from "react";

export default function useWindowSize() {
  // window
  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // get size
  const getSize = useCallback(() => {
    return {
      width: window.innerWidth || 0,
      height: window.innerHeight || 0
    };
  }, []);

  // onResize
  const onResize = useCallback(() => {
    setWindowSize(getSize());
  }, [ getSize, setWindowSize ]);

  //@ts-ignore
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ getSize, onResize ]);

  return windowSize;
}
