import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { Banner, TextBanner, ButtonBanner } from "./styles";
import { ICookie } from "./interfaces";

// message
const CookieBanner: FC<ICookie> = ({ lang }) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const load = async () => {
      await axios
        .get(`data/cookie-${lang.lang}.json`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    };

    load();
  }, [lang]);

  function closeBanner() {
    document.getElementById("banner")!.style.display = "none";
  }

  return (
    <Banner id="banner">
      <TextBanner>
        {data?.cookieText}
        <a href={data?.link} target="_blank">
          {data?.firstLinkText}
        </a>
        {data?.cookieText2}{" "}
        <a href={data?.secondLink} target="_blank">
          {data?.secondLinkText}
        </a>{" "}
      </TextBanner>
      <ButtonBanner onClick={closeBanner}>{data?.button}</ButtonBanner>
    </Banner>
  );
};

export default CookieBanner;
