import styled from "styled-components";
import { Form } from 'formik';

const ContactFormDiv = styled.div`
  background-color: transparent;
  padding: 0px 60px 60px;
  width: 50%;

  .contact-form {
    &--title {
      margin: 5px 0 10px;
      width: 100%;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    order: 1;
    padding: 0px 35px 50px;
    width: 100%;
  }
`;

const ContactFormFooterDiv = styled.div`
  text-align: right;
  width: 100%;
`;

const ContactFormForm = styled(Form)`
  float: left;
  width: 100%;

  button {
    &[type="submit"] {
      margin-top: 20px;
    }
  }
`;

export {
  ContactFormDiv,
  ContactFormFooterDiv,
  ContactFormForm
}