import styled from "styled-components";

const OurOperationVideoDiv = styled.div`
  right: 0;
  position: absolute;
  top: calc(50% + 10px);
  transform: translate(0, -50%);
  width: calc(50% - 70px);

  .our-operation-video {
    &--image,
    &--info {
      width: 100%;
    }

    &--icon {
      height: 100%;
      left: 0;
      padding: 0 20px;
      position: absolute;
      top: 0;
      width: 190px;

      .icon {
        color: ${({ theme }) => theme.white_900};
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        &.icon-play {
          font-size: 70px;
          left: calc(50% + 10px);
          transform: translate(-50%, -50%);
        }

        &.icon-line {
          font-size: 115px;
          opacity: 0.2;
          top: 0;
          transform: translate(0, 0);
        }
      }
    }

    &--image {
      img {
        width: 100%;
      }
    }

    &--info {
      background-color: ${({ theme }) => theme.green_750};
      padding: 35px 20px 35px 165px;

      .time,
      .title {
        color: ${({ theme }) => theme.white_900};
        margin: 0;
        width: 100%;
      }

      .time {
        border-radius: 2px;
        background-color: ${({ theme }) => theme.blue_300};
        font-weight: 600;
        padding: 5px;
        width: auto;
      }

      .title {
        font-weight: 600;
        margin: 0 0 10px;
        text-transform: uppercase;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    left: initial;
    padding: 0 20px;
    position: relative;
    top: initial;
    transform: none;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    .our-operation-video {
      &--icon {
        padding: 0 10px;
        width: 120px;

        .icon {
          &.icon-play {
            left: 55px;
            font-size: 40px;
          }

          &.icon-line {
            font-size: 70px;
          }
        }
      }

      &--info {
        padding: 15px 20px 15px 90px;

        .title {
          margin: 0 0 5px;
        }
      }
    }
  }
`;

const OurOperationVideoButton = styled.div`
  background-color: transparent;
  outline: none;
`;

export {
  OurOperationVideoDiv,
  OurOperationVideoButton
}