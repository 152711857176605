import styled from "styled-components";

const MapItemModalDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  max-height: calc(80vh - 80px);
  max-width: 460px;
  left: calc(100% + 60px);
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  top: inherit;
  width: 460px;
  z-index: 99;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: 460px;
    max-height: calc(100vh - 100px);
    left: 50%;
    transform: translate(-50%, 120px);
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    max-width: 290px;
  }
`;

const MapItemModalHeaderDiv = styled.div`
  background-color: ${({ theme }) => theme.green_900};
  border-radius: 5px;
  left: 8px;
  margin: 0;
  position: sticky;
  top: 8px;
  width: calc(100% - 16px);
  z-index: 2;

  .button {
    background-color: transparent;
    border: none;
    right: 20px;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    .icon {
      font-size: 15px;
      color: ${({ theme }) => theme.green_700};
    }
  }

  .title {
    color: ${({ theme }) => theme.green_700};
    font-weight: 600;
    padding: 0 25px;
    width: 100%;
  }
`;

const MapItemModalBodyDiv = styled.div`
  width: 100%;
`;

const MapItemModalSubtitleDiv = styled.div`
  margin: 0 0 15px;
  padding: 0 35px;
  text-align: center;
  width: 100%;

  p {
    color: ${({ theme }) => theme.black_300};
    font-weight: 500;
    margin: 0;
    padding: 9px 10px;
    width: 100%;

    &:before {
      background-color: ${({ theme }) => theme.green_900};
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.15;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;

export {
  MapItemModalDiv,
  MapItemModalHeaderDiv,
  MapItemModalBodyDiv,
  MapItemModalSubtitleDiv
}