import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';

import ListItem from './ListItem';

import { IList } from './interfaces';

import { ContainerDiv } from '../../layout/Container/styles';
import { ListDiv } from './styles';

// list
const List: FC<IList> = ({
  lang
}) => {
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/list-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <ListDiv>
      <ContainerDiv>
        {Array.isArray(data?.items) && data?.items.map((item: any, index: number) =>
          <ListItem {...item} key={index} />)}
      </ContainerDiv>
    </ListDiv>
  );
};

export default List;