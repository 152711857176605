import React, { createContext, FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import UseLocalStorage from '../../hooks/useLocalStorage';

import { ILangContext, ILangItem, ILangProvider } from './interfaces';

import { langs } from './langs';

// lang context
const LangContext = createContext({} as ILangContext);

// lang provider
const LangProvider: FC<ILangProvider> = ({ children }) => {
  const { i18n } = useTranslation();
  
  const [ lang, setLang ] = UseLocalStorage<any>('3r_lang', {
    lang: 'pt',
    name: 'pt',
  });

  // set language
  const setLanguage = useCallback((value: string) => {
    if (value === lang.name) return false;
    
    const valueLang = langs.find(({ name }: ILangItem) => name === value);

    if (valueLang instanceof Object) {
      setLang(valueLang);
    }
  }, [ setLang, lang ]);

  // get lang
  const getLanguage = useCallback((lang: string) =>
    langs.filter((item: any) => item?.lang === lang)[0], []);

  // use effect
  useEffect(() => {
    i18n.changeLanguage(lang?.lang);
  }, [ lang, i18n ]);

  // render
  return (
    <LangContext.Provider value={{
      lang,
      langs,
      setLang: setLanguage,
      getLang: getLanguage,
    }}>
      {children}
    </LangContext.Provider>
  );
};

export { LangContext, LangProvider };
export default LangProvider;