import styled from "styled-components";

const NewsArrowButton = styled.button`
  background-color: ${({ theme }) => theme.green_950};
  bottom: 0;
  border: none;
  border-radius: 5px;
  height: 32px;
  outline: none;
  position: absolute;
  width: 32px;

  .icon {
    color: ${({ theme }) => theme.green_900};
    font-size: 18px;
    left: 50%;
    position: absolute;
    top: 50%;
  }

  &.slick-prev {
    left: 20px;

    .icon {
      left: calc(50% - 2px);
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }

  &.slick-next {
    right: 20px;

    .icon {
      left: calc(50% + 2px);
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  &:hover {
    .icon {
      color: ${({ theme }) => theme.green_700};
    }
  }
`;

export {
  NewsArrowButton
}