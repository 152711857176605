import React, { FC } from 'react';

import WebdoorInfoItem from './WebdoorInfoItem';

import { IWebdoorInfo } from './interfaces';

import { ContainerDiv } from '../../../layout/Container/styles';
import { WebdoorInfoDiv } from './styles';

// webdoor info
const WebdoorInfo: FC<IWebdoorInfo> = ({
  animate,
  current,
  items
}) => {
  // render
  return (
    <WebdoorInfoDiv>
      <ContainerDiv>
        <div className="webdoor-info--container">
          {items && items.map((item: any, index: number) =>
            <WebdoorInfoItem
              animate={animate}
              active={current === index}
              item={item}
              key={index} />)}
        </div>
      </ContainerDiv>
    </WebdoorInfoDiv>
  );
};

export default WebdoorInfo;