import axios from 'axios';

import React, { FC, useEffect, useState } from 'react';
import PageInfo from '../../components/PageInfo';
import TableComponent from '../../components/TableComponent';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

import { IProdutos } from './interfaces';
import { ProdutosDiv } from './styles';

// Interna
const Produtos: FC<IProdutos> = ({
  animate,
  lang
}) => {

  const [ data, setData ] = useState<any>({});

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/products-info-pt.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, []);

  // render
  return (
    <ProdutosDiv>
      <Header
        animate={animate}
        menu={[]}
        subMenu={[]}
        type={2} 
      />

      {data && <PageInfo {...data} />}

      <TableComponent />

      <Footer lang={lang} />
    </ProdutosDiv>
  );
};

export default Produtos;