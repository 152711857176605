import styled from 'styled-components';

const FooterFooter = styled.footer`
  background-color: ${({ theme }) => theme.white_900};
  border-top: 1px solid ${({ theme }) => theme.black_100};
  height: 90px;
  float: left;
  padding: 0 20px;
  width: 100%;

  > div {
    padding: 25px 0 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width : 1200px) {
    height: auto;

    > div {
      flex-direction: column;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    height: auto;
  }
`;

const FooterLogoDiv = styled.div`
  width: auto;

  > p {
    color: ${({ theme }) => theme.black_300};
    margin-left: 15px;
    font-weight: 500;
    font-size: 13px;
  }

  > svg {
    float: left;
  }

  @media only screen and (max-width : 1365px) {
    > p {
      max-width: 180px;
      margin-top: 0;
    }
  }

  @media only screen and (max-width : 768px) {
    display: flex;
    align-items: center;
    
    > p {
      max-width: unset;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    padding: 0 20px;
    text-align: center;
    width: 100%;

    > p {
      margin: 20px 0;
    }

    > svg {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
`;

const FooterPrivacidadeDiv = styled.div`
  float: right;
  padding: 10px 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-right: 40px;

  .icon {
    color: ${({ theme }) => theme.blue_300};
    font-size: 15px;
  }

  .link {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: #068CA0;
    text-decoration: none;
    max-width: 100px;
    text-align: center;
  }

  @media only screen and (max-width : 1200px) {
    margin-right: 0;
  }

  @media only screen and (max-width : 768px) {
    flex-direction: column;
    align-items: center;

    .link {
      max-width: unset;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    text-align: center;
    width: 100%;

    .link,
    .link:last-child {
      float: none;
      margin: 0 10px;
    }
  }
`;

const FooterSocialNetworksDiv = styled.div`
  float: right;
  padding: 10px 0;
  margin-right: 40px;

  .icon {
    color: ${({ theme }) => theme.blue_300};
    font-size: 15px;
  }

  .link {
    margin-right: 40px;

    &:last-child {
      margin: 0;
    }
  }

  @media only screen and (max-width : 1200px) {
    margin-right: 0;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    text-align: center;
    width: 100%;

    .link,
    .link:last-child {
      float: none;
      margin: 0 10px;
    }
  }
`;

const FooterAssinaturaDiv = styled.div`
  display: flex;
  height: 22px;
  width: auto;

  .assinatura-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
    text-decoration: none;
    color: #434343;
    font-size: 13px;
    font-weight: 500;
    font-family: Montserrat;
  }

  @media only screen and (max-width : 1200px) {
    margin-top: 20px;
  }
`;

export {
  FooterFooter,
  FooterLogoDiv,
  FooterPrivacidadeDiv,
  FooterSocialNetworksDiv, 
  FooterAssinaturaDiv
}