import styled from "styled-components";

const MessageDiv = styled.div`
  background-color: ${({ theme }) => theme.green_900};
  padding: 140px 0;
  width: 100%;

  .message {
    &--title,
    &--subtitle,
    &--text,
    &--footer {
      color: ${({ theme }) => theme.white_900};
      margin: 0;
      width: 100%;
    }

    &--title {
      max-width: 630px;
      font-size: 45px;
      line-height: 50px;
      font-weight: 500;
      color: ${({ theme }) => theme.green_700};
      margin: 0 0 35px;
    }

    &--subtitle {
      font-size: 25px;
      line-height: 28px;
      font-weight: 500;
      margin: 0 0 15px;
    }

    &--paragraph-title {
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
      color: ${({ theme }) => theme.white_900};
      margin-top: 0;
      margin-bottom: 10px;
    }

    &--text {
      font-size: 17px;
      line-height: 27px;
      font-weight: 300;
      margin: 0 0 35px;
      white-space: pre-line;
    }

    &--footer {
      font-weight: 600;
      margin: 0 0 35px;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 90px 0;

    .message {
      &--title {
        font-size: 36px;
        margin: 0 0 40px;
      }

      &--subtitle {
        font-size: 21px;
      }
    }
  }
`;

const NoticeDiv = styled.div`
  width: 100%;
  background: #194D3D;
  padding: 20px 45px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .notice {
    &--text {
      font-size: 17px;
      line-height: 27px;
      font-weight: 400;
      color: ${({ theme }) => theme.white_900};
      margin: 0;

      a {
        text-decoration: none;
        color: #A1D64A;
        display: inline;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 11px 14px;
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .contact-info {
    &--text {
      font-size: 17px;
      line-height: 27px;
      font-weight: 300;
      color: #FFFFFF;
      margin: 0;

      a {
        text-decoration: none;
        color: #A1D64A;
        display: inline;
      }
    }
  }
`;

export {
  MessageDiv,
  NoticeDiv,
  ContactInfo
}
