import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MapItemModalListUl, MapItemModalListLi } from './styles';

import { IMapItemModalList } from './interfaces';

// map item modal list
const MapItemModalList: FC<IMapItemModalList> = ({
  item,
  items
}) => {
  const { t } = useTranslation();

  // render
  return (
    <MapItemModalListUl>
      {items && items.map((key: string, index: number) =>
        <MapItemModalListLi className="item" key={index}>
          <span className="label">{t(`map.${key}`)}</span>
          <span className="value">{item[key]}</span>
        </MapItemModalListLi>)}
    </MapItemModalListUl>
  );
};

export default MapItemModalList;