import styled from "styled-components";

export const FormFieldWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
    flex: 1;

    &:has(input[required]) {
        label:before {
            content: "*";
            margin-right: 3px;
            color: red;
        }
    }

    &:has(input:focus), 
    &:not(:has(input:placeholder-shown)) {
        label {
            font-size: 12px;
            top: 6px;
            transform: translateY(0);
        }
    }

    label {
        font-weight: 600;
        font-size: 16px;
        color: ${({ theme }) => theme.blue_300};
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        transition: all ease-in-out 0.4s;
        z-index: 1;
        pointer-events: none;
    }

    input {
        width: 100%;
        height: 48px;
        padding: 18px 20px 0;
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 17.6px;
        color: ${({ theme }) => theme.black_300};
        border: 1px solid ${({ theme }) => theme.white_700};
        border-radius: 5px;
        outline: none;
    }

    :has(.field-error) {
        margin-bottom: 40px;
    }
`