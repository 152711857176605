import styled from "styled-components";

const AsgItemDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  width: 100%;

  &:nth-child(3n+1) {
    .asg-item {
      &--icon {
        .icon {
          color: ${({ theme }) => theme.green_300};

          &:after {
            background-color: ${({ theme }) => theme.green_700};
          }
        }
      }
    }

    svg {
      fill: ${({ theme }) => theme.green_300};
    }
  }

  &:nth-child(3n+2) {
    .asg-item {
      &--icon {
        .icon {
          color: ${({ theme }) => theme.blue_300};

          &:after {
            background-color: ${({ theme }) => theme.blue_300};
          }
        }
      }
    }

    svg {
      fill: ${({ theme }) => theme.green_700};
    }
  }

  &:nth-child(3n+3) {
    .asg-item {
      &--icon {
        .icon {
          color: ${({ theme }) => theme.green_900};

          &:after {
            background-color: ${({ theme }) => theme.green_900};
          }
        }
      }
    }

    svg {
      fill: ${({ theme }) => theme.black_700};
    }
  }
`;

const AsgItemTitleDiv = styled.div`
  padding: 0 35px;
  width: 100%;

  .asg-item {
    &--icon {
      right: 50px;
      position: absolute;
      top: 30px;

      .icon {
        font-size: 105px;
        padding: 25px 0 0;

        &:after {
          border-radius: 100%;
          content: '';
          height: 117px;
          left: 50%;
          opacity: 0.3;
          position: absolute;
          transform: translate(-50%, 0);
          top: 0;
          width: 117px;
        }
      }
    }
  }

  .letter {
    color: ${({ theme }) => theme.green_700};
    font-style: normal;
    font-weight: 200;
    font-size: 200px;
    margin: 0;
    line-height: 1em;
    text-transform: uppercase;
  }

  svg {
    right: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 240px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0 15px;

    .asg-item {
      &--icon {
        right: 25px;
      }
    }

    svg {
      width: 220px;
    }
  }
`;

const AsgItemContentDiv = styled.div`
  padding: 0 35px;
  width: 100%;

  .strong {
    color: ${({ theme }) => theme.blue_300};
    font-weight: 600;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0 25px;

    .strong {
      margin-top: 0;
    }
  }
`;

const AsgItemListUl = styled.ul`
  margin: 0;
  padding: 10px 0;
  width: 100%;

  li {
    margin: 0 0 20px;
    width: 100%;

    p {
      color: ${({ theme }) => theme.black_300};
      display: inline;
      margin: 0;
      line-height: 1.6em;
      opacity: 0.8;
    }

    &:before {
      border-radius: 100%;
      background-color: ${({ theme }) => theme.green_700};
      content: '';
      display: inline;
      height: 9px;
      float: left;
      margin: 8px 10px 0 0;
      width: 9px;
    }
  }
`;

export {
  AsgItemDiv,
  AsgItemTitleDiv,
  AsgItemContentDiv,
  AsgItemListUl
}