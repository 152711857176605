import React, { FC } from "react";
import { FormButtonWrapper } from "./styles";

const FormSubmitButton: FC<any> = ({
    label,
    disabled
}) => {
    return (
        <>
            <FormButtonWrapper>
                <input type="submit" value={label} disabled={disabled}/>
            </FormButtonWrapper>
        </>
    );
};

export default FormSubmitButton;

