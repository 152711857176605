import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '../../Typography/styles';
import { MapTitleModalDiv, MapTitleModalHeaderDiv } from './styles';

// map title modal
const MapTitleModal: FC<any> = ({
  title_modal,
  onClose,
  modal_text,
  modal_information_1,
  modal_information_2,
  modal_information_value_1,
  modal_information_value_2
}) => {

  // render
  return (
    <MapTitleModalDiv>
      <MapTitleModalHeaderDiv>
        <Text className="title">{title_modal}</Text>
        <button className='title-modal-button' onClick={onClose}>
          <span className="icon icon-times"></span>
        </button>
      </MapTitleModalHeaderDiv>

      <div className='title-modal-body'>
        <p>{modal_text}</p>

        <div className='title-modal-body-information'>
          <p>{modal_information_1}</p>
          <span>{modal_information_value_1}</span>
        </div>

        {(modal_information_2 && modal_information_value_2) && (
          <div className='title-modal-body-information'>
            <p>{modal_information_2}</p>
            <span>{modal_information_value_2}</span>
          </div>
        )}
      </div>
    </MapTitleModalDiv>
  );
};

export default MapTitleModal;