import styled from 'styled-components';

const OurOperationDiv = styled.div`
  background-color: ${({ theme }) => theme.white_100};
  width: 100%;
  

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0 0 70px;
  }
`;

export {
  OurOperationDiv,
}