import styled from "styled-components";

const MainDiv = styled.div`
  width: 100%;
`;

const LoaderAnimateDiv = styled.div<{ animation?: string }>`
  animation: ${({ animation }) => animation};
  animation-fill-mode: forwards;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  @keyframes show {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hidden {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes hiddenWithScale {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }
`;

export  {
  LoaderAnimateDiv,
  MainDiv,
}