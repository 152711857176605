import styled from "styled-components";

const WebdoorDiv = styled.div`
  height: 100vh;
  min-height: 765px;
  max-height: 865px;
  margin: 0;
  pointer-events: auto;
  width: 100vw;
  z-index: 2;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    min-height: none;
    height: calc(100vh - 80px);
  }
`;

export {
  WebdoorDiv
}