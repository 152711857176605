import styled from "styled-components";

const MapSection = styled.section`
  margin: 0;
  pointer-events: auto;
  width: 100vw;
  z-index: 2;
  position: relative;

  &:before {
    background-color: ${({ theme }) => theme.green_800};
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: calc(((100vw - 1400px) / 2) + 673px);
    z-index: 2;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1400px) {
    &:before {
      width: 673px;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    &:before {
      width: 480px;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    &:before {
      width: 100%;
    }
  }
`;

const MapContainerDiv = styled.div`
  background-color: transparent;
  float: left;
  margin: 0 0 100px;
  max-width: 673px;
  padding: 86px 60px 0 210px;
  width: 100%;
  z-index: 3;

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    max-width: 470px;
    padding: 0 60px 0 90px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    align-content: center;
    align-items: center;
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    max-width: none;
    padding: 0 35px;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 0 35px;
  }
`;

const MapTitleDiv = styled.div`
  color: ${({ theme }) => theme.white_900};
  position: absolute;
  top: 77px;
  left: 743px;
  text-align: center;
  width: fit-content;
  z-index: 2;

  h2 {
    margin: 0;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 60px 0 40px;
    padding: 0 35px;
    text-align: left;
  }
`;

export {
  MapSection,
  MapContainerDiv,
  MapTitleDiv
}