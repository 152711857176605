import styled from "styled-components";

const MapItemModalListUl = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0 35px 10px;
  width: 100%;
  `;

const MapItemModalListLi = styled.li`
  align-items: center;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.green_700};
  display: inline-flex;
  float: left;
  flex-flow: row wrap;
  justify-content: center;
  padding: 15px 0;
  width: 100%;

  .label,
  .value {
    float: left;
    line-height: 1em;
    width: 50%;
  }

  .label {
    color: ${({ theme }) => theme.black_300};
    font-weight: 600;
    padding-right: 40px;
  }

  .value {
    color: ${({ theme }) => theme.blue_300};
    font-weight: 500;
    line-height: 1.3em;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export {
  MapItemModalListUl,
  MapItemModalListLi
}