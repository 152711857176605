import React, { FC, useContext } from "react";

import Asg from "../../components/Asg";
import Contact from "../../components/Contact";
import Map from "../../components/Map";
import List from "../../components/List";
import Loader from "../../components/Loader";
import News from "../../components/News";
import OurOperation from "../../components/OurOperation";
import WeAre from "../../components/WeAre";
import Webdoor from "../../components/Webdoor";
import CookieBanner from "../../components/CookieBanner";
import ModalHome from "../../components/ModalHome";

import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import { MainContext } from "../../providers/MainProvider";

import { IHome } from "./interfaces";

import { HomeDiv } from "./styles";

// home
const Home: FC<IHome> = ({
  animate,
  lang
}) => {
  const { header, header_sub_menu } = useContext(MainContext);

  // render
  return (
    <HomeDiv>
      <ModalHome />

      <Header
        animate={animate}
        menu={header}
        subMenu={header_sub_menu} />
      
      <Webdoor
        animate={animate}
        lang={lang} />

      <WeAre lang={lang}/>

      <List lang={lang} />

      <OurOperation lang={lang} />

      <Map lang={lang} />

      <Asg lang={lang} />

      <News lang={lang} />

      <Contact lang={lang} />

      <Footer lang={lang} />
      
      <Loader animate={animate} />

      <CookieBanner lang={lang}/>
    </HomeDiv>
  );
};

export default Home;