import styled from "styled-components";

const InputFormLabelDiv = styled.div`
  float: left;
  margin: 10px 0;
  position: relative;
  width: 100%;
`;

export {
  InputFormLabelDiv,
}