import React, { FC } from 'react';

import MapItemModal from '../MapItemModal';

import { Text, TextSubtitleMin } from '../../Typography/styles';
import { MapItemDiv, MapItemTitleDiv } from './styles';

import { IMapItem } from './interfaces';

import { ReactComponent as Picker } from '../../../assets/icons/locale.svg';

// map item
const MapItem: FC<IMapItem & { setActiveItemModal: (item: any) => void, activeItemModal: any }> = ({
  active,
  item,
  setCurrent,
  setActiveItemModal,
  activeItemModal
}) => {
  const handleOpenItemModal = () => {
    setCurrent(item);
    setActiveItemModal(item);
  };

  return (
    <>
      <MapItemDiv
        data-active={active}>
        <MapItemTitleDiv data-active={active}>
          <Picker />
          <Text className="map-item--number">{item?.number}</Text>
        </MapItemTitleDiv>

        <Text className="map-item--title">{item?.name}</Text>

        <TextSubtitleMin className="map-item--description">{`${item?.basin}, ${item?.localization}`}</TextSubtitleMin>
        <TextSubtitleMin className="map-item--type">{item?.reservations}</TextSubtitleMin>

        <button className="map-item--button" onClick={handleOpenItemModal}>
          <span className="icon icon-arrow-bold"></span>
        </button>
      </MapItemDiv>

      {activeItemModal === item && <MapItemModal item={item} onClose={() => setActiveItemModal(null)} />}
    </>
  );
};

export default MapItem;