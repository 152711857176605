import styled from "styled-components";

const MapListDiv = styled.div`
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    width: 300px;
  }
`;

const MapListContainerDiv = styled.div`
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;
`;

const MapListTitleDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid #005B68;

  .icon,
  .text {
    color: ${({ theme }) => theme.white_900};
  }

  .title-content {
    width: 100%;
    padding: 32px 0 15px;

    .title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .text-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 18px;
        
        .text {
          font-weight: 600;
          text-transform: uppercase;
          max-width: 120px;
        }
      }
  
      .title-modal-button {
        width: 19px;
        height: 38px;
        border-radius: 2px;
        background-color: #A1D64A;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
  
        span {
          font-size: 9px;
          color: #215B4A;
        }
      }
    }
  }
`;

export {
  MapListDiv,
  MapListContainerDiv,
  MapListTitleDiv
}