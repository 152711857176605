import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '../../Typography/styles';
import { AsgItemDiv, AsgItemTitleDiv, AsgItemContentDiv, AsgItemListUl } from './styles';

import { ReactComponent as TitleBg } from '../../../assets/svg/bg-asg.svg';

import { IAsgItem } from './interfaces';

// asg item
const AsgItem: FC<IAsgItem> = ({
  letter,
  icon,
  title,
  items
}) => {
  const { t } = useTranslation();

  // render
  return (
    <AsgItemDiv data-aos='fade-up'>
      <AsgItemTitleDiv>
        <TitleBg />

        <p className="letter">{letter}</p>

        <div className="asg-item--icon">
          <span className={`icon ${icon}`}></span>
        </div>
      </AsgItemTitleDiv>
      
      <AsgItemContentDiv>
        <Text className="strong" data-aos="fade-in">{title}</Text>

        <AsgItemListUl>
          {items && items.map((text: string, index: number) =>
            <li data-aos="fade-up" data-aos-delay={index * 20} key={index}>
              <Text>{t(text)}</Text>
            </li>)}
        </AsgItemListUl>
      </AsgItemContentDiv>
    </AsgItemDiv>
  );
};

export default AsgItem;