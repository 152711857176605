import styled from "styled-components";
import background from "../../assets/svg/background.svg"

const ContainerDiv = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1400px;
  text-align: left;
  width: calc(100% - 80px);

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    width: 100%;
    height: 100%;
  }
`;

const ContainerMinDiv = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 855px;
  text-align: left;
  width: calc(100% - 80px);

  &.pageInfo {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:before {
      content: url(${background});
      position: absolute;
      left: -220px;
      top: 20px;
    }
  }

  @media (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;

    &.pageInfo {
      padding-right: 0;
      padding-left: 0;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    width: 100%;
  }
`;

export {
  ContainerDiv,
  ContainerMinDiv
}