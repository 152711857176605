import styled from "styled-components";

export const FormButtonWrapper = styled.div`
    width: fit-content;
    align-self: center;

    input[type=submit] {
        min-width: 164px;
        min-height: 48px;
        background-color: ${({ theme }) => theme.green_300};
        border: 2px solid ${({ theme }) => theme.green_300};
        border-radius: 5px;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 16.5px;
        color: ${({ theme }) => theme.white_900};
        text-transform: uppercase;
        cursor: pointer;
        transition: all ease-in-out 0.3s;

        &[disabled] {
            background-color: ${({ theme }) => theme.white_700};
            border: 2px solid ${({ theme }) => theme.white_700};
            color: ${({ theme }) => theme.black_100};
            cursor: not-allowed; 
        }
    }
`;