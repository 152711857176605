import styled from "styled-components";

const ModalDiv = styled.div`
  background-color: transparent;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  &:before {
    background-color: ${({ theme }) => theme.black_900};
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const ModalContainerDiv = styled.div`
  height: auto;
  max-height: calc(100% - 40px);
  max-width: 900px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 60px);

  &[data-type="video"] {
    max-width: none;
    width: auto;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    &[data-type="video"] {
      max-width: none;
      padding: 0 20px;
      width: 100%;
    }
  }
`;

const ModalHeaderDiv = styled.div`
  padding: 10px 0;
  width: 100%;

  button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    float: right;
    outline: none;
    top: 0px;

    .icon {
      color: ${({ theme }) => theme.white_900};
      font-size: 16px;
    }
  }

  &[data-type="video"] {
    
  }
`;

const ModalBodyDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  &[data-type="video"] {
    > div {
      width: 100%;
    }

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  @media (max-width: 1400px) {
    margin-right: 15px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    &[data-type="video"] {
      display: inline-block;
      position: relative;
      margin: 0;
      width: 100%;
      
      > div {
        
        width: 100% !important;

        > div {
          height: 0;
          margin: 0;
          overflow: hidden;
          padding-bottom: 56.25%;
          padding-left: 0px;
          padding-top: 10px;
        }
      }
    }
  }
`;

export {
  ModalDiv,
  ModalContainerDiv,
  ModalHeaderDiv,
  ModalBodyDiv
}