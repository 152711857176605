import styled from "styled-components";

const NavbarMenuNav = styled.nav`
  width: auto;

  .navbar-menu {
    &--link {
      color: ${({ theme }) => theme.white_900};
      cursor: pointer;
      float: left;
      font-weight: 500;
      font-size: 14px;
      margin: 0 30px;
      line-height: 1em;
      text-align: left;
      text-decoration: none;
      user-select: none;

      .text {
        text-transform: uppercase;
      }

      &:before {
        background-color: ${({ theme }) => theme.white_900};
        border-radius: 100%;
        content: '';
        height: 6px;
        right: -33px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 6px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    .navbar-menu {
      &--link {
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.green_700};
        margin: 0;
        padding: 25px 20px;
        width: 100%;

        &:before {
          display: none;
        }
      }
    }
  }
`;

export {
  NavbarMenuNav,
}