import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";

import PageInfo from '../../components/PageInfo';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

import { IAgendamentos } from './interfaces';
import { AgendamentosDiv, AgendamentosForm, FormFieldGroup, FormFieldGroupTitle, FormFieldInline, ResponseMessageDiv } from './styles';
import FormField from '../../components/FormField';
import FormSubmitButton from '../../components/FormSubmitButton';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';

const { REACT_APP_AGENDAMENTO_URL, REACT_APP_GOOGLE_CAPTCHA_PUBLIC }: any = process.env;

// Interna
const Agendamentos: FC<IAgendamentos> = ({
  animate,
  lang,
}) => {
  const [ data, setData ] = useState<any>({});
  const [ offices, setOffices ] = useState<any>([]);
  const [ dates, setDates ] = useState<any>([]);
  const [ availableHours, setAvailableHours ] = useState<any>([]);
  const [ categories, setCategories ] = useState<any>([]);

  const selectedOfficeId: any = useRef();
  const startDate: any = useRef();
  const selectedDateId: any = useRef();
  const selectedCategoryId: any = useRef();
  const recaptchaRef: any = useRef();
  const loader: any = useRef();

  let numberOfCompanions = 1

  const { t }: any = useTranslation();

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/schedule-info-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };
    const getOffices = axios({
      method: "GET",
      url: `${REACT_APP_AGENDAMENTO_URL}/api/escritorios-administrativos/`,
    });
    const getCategoriesPT = axios({
      method: "GET",
      url: `${REACT_APP_AGENDAMENTO_URL}/api/categorias-pt/`,
    });

    const getCategoriesEN = axios({
      method: "GET",
      url: `${REACT_APP_AGENDAMENTO_URL}/api/categorias-en/`,
    });

    axios
      .all([getOffices, getCategoriesPT, getCategoriesEN])
      .then(
        axios.spread((...responses) => {
          console.log(responses)
          const selectOffices = responses[0]
          const selectCategoriesPT = responses[1];
          const selectCategoriesEN = responses[2];

          if (selectOffices.data) {
            setOffices(selectOffices.data);
          }

          if (lang.lang === 'pt') {
            setCategories(selectCategoriesPT.data);
          }
          else {
            setCategories(selectCategoriesEN.data);
          }
        })
      );

    load();
    saveData()
    handleCompanionButton()
    inputPhoneMask()
    inputCnpjMask()
    inputCpfMask()
  }, [lang]);

  const returnDates = (id: number) => {
    axios.get(`${REACT_APP_AGENDAMENTO_URL}/api/datas/${id}`)
      .then((res) => setDates(res.data))
      .catch(err => console.log(err))
  }

  const availableDates = dates.map((str: any) => {
    return new Date(str.replace(/-/g, '\/'))
  })

  const getDateTimes = (date: Date) => {
    const dateFormated = date.toISOString().slice(0, 10);

    axios.get(`${REACT_APP_AGENDAMENTO_URL}/api/horas/${selectedOfficeId.current}/${dateFormated}`)
      .then((res) => {
        const response = res.data;
        response.forEach((element: any) => {
          element.hora = (element.hora).slice(0,5);
        });

        setAvailableHours(response);
      })
      .catch(err => console.log(err))
  }

  const loading = (div: HTMLElement) => {
    const parent = div;

    const loading = document.createElement('div');
    loading.classList.add('loading');
    const loadingSpinner = document.createElement('div');
    loadingSpinner.classList.add('loading-spinner');
    loading.appendChild(loadingSpinner);

    if (loader.current === true) {
      parent?.appendChild(loading);
    }
    else if (loader.current === false) {
      const addedLoading = parent.querySelector('.loading')
      addedLoading?.remove();
    }
  }

  const saveData = () => {
    const form = document.querySelector('form');

    form?.addEventListener('submit', (e: any) => {
      e.preventDefault();

      new FormData(form);
      loading(form);
    })

    form?.addEventListener('formdata', (e: any) => {
      const data = e.formData;
      const recaptcha = recaptchaRef.current.getValue();
      let sendDataObject = {};
      loader.current = true;
      
      if (lang.lang === 'pt') {
        sendDataObject = {
          "horario": selectedDateId.current,
          "empresa": data.get('empresa'),
          "cnpj": data.get("cnpj"),
          "endereco": data.get("endereco"),
          "telefone_empresa": data.get("telefone_empresa"),
          "categoria_pt": selectedCategoryId.current,
          "nome": data.get("nome"),
          "cargo": data.get("cargo"),
          "rg": data.get("rg"),
          "cpf": data.get("cpf"),
          "telefone": data.get("telefone"),
          "email": data.get("email"),
          "acompanhante_set": getCompanionSet(),
          "recaptcha": recaptcha
        }
      }
      else {
        sendDataObject = {
          "horario": selectedDateId.current,
          "empresa": data.get('empresa'),
          "endereco": data.get("endereco"),
          "telefone_empresa": data.get("telefone_empresa"),
          "categoria_en": selectedCategoryId.current,
          "nome": data.get("nome"),
          "cargo": data.get("cargo"),
          "passaporte": data.get("passaporte"),
          "telefone": data.get("telefone"),
          "email": data.get("email"),
          "acompanhante_set": getCompanionSet(),
          "recaptcha": recaptcha
        }
      }

      axios.post(`${REACT_APP_AGENDAMENTO_URL}/api/visita-${lang.lang === 'pt' ? 'pt' : 'en'}/`, sendDataObject, {
        headers: {
          "Accept-Language": `${lang.lang === 'pt' ? 'pt' : 'en'}`
        }
      })
        .then(() => {
          loader.current = false;
          loading(form);
          const responseMessageDiv = document.querySelector(".response-message") as HTMLElement;

          if (responseMessageDiv && form) {
            form.style.display = 'none';
            responseMessageDiv.style.display = 'flex';
          }
        })
        .catch((err) => {
          loader.current = false;
          loading(form);
          const response = err.response.data;
          console.log(response)
          const parentDivList = form.querySelectorAll(".form-field-wrapper:not(:has(#g-recaptcha))");

          parentDivList.forEach((listItem) => {
            const field = listItem.querySelector('input:not([value=""])')?.getAttribute('name');

            if (response[`${field}`]) {
              const node = document.createElement('p');
              node.classList.add('field-error');
              node.innerHTML = response[`${field}`][0];
              listItem.appendChild(node);
            }
          })

          if (response[`acompanhante_set`]) {
            for(let i=0; i<response[`acompanhante_set`].length; i++) {
              const companionFormItem = document.querySelector(`.companion-form-${i+1}`);
              const companionNameDiv = companionFormItem?.querySelector(`.form-field-wrapper:has(input[name=acompanhante_${i+1}_nome])`);
              const companionCPFDiv = companionFormItem?.querySelector(`.form-field-wrapper:has(input[name=acompanhante_${i+1}_cpf])`);
              const companionPassportDiv = companionFormItem?.querySelector(`.form-field-wrapper:has(input[name=acompanhante_${i+1}_passaporte])`);

              if(response.acompanhante_set[i].nome) {
                const node = document.createElement('p');
                node.classList.add('field-error');
                node.innerHTML = response.acompanhante_set[i].nome[0];
                companionNameDiv?.appendChild(node);
              }
              if (response.acompanhante_set[i].cpf) {
                const node = document.createElement('p');
                node.classList.add('field-error');
                node.innerHTML = response.acompanhante_set[i].cpf[0];
                companionCPFDiv?.appendChild(node);
              }
              if (response.acompanhante_set[i].passaporte) {
                const node = document.createElement('p');
                node.classList.add('field-error');
                node.innerHTML = response.acompanhante_set[i].passaporte[0];
                companionPassportDiv?.appendChild(node);
              }
            }
          }
        })
    })
  }

  const handleCompanionButton = () => {
    const button = document.querySelector('.add-companion');
    const companionDiv = document.querySelector('.companion-div');
    const emptyDiv = document.querySelector('#empty-div');
    const emptyDivContent = emptyDiv?.innerHTML;

    button?.addEventListener('click', () => {
      numberOfCompanions = numberOfCompanions + 1;

      const newCompanion = document.createElement('div');
      newCompanion.classList.add(`companion-form-${numberOfCompanions}`);
      if (emptyDivContent)
      newCompanion.innerHTML = emptyDivContent.replaceAll('prefix', `${numberOfCompanions}`)

      companionDiv?.appendChild(newCompanion)
    })
  }

  const getCompanionSet = () => {
    let companionSet = [];

    for(let i=1; i<=numberOfCompanions; i++) {
      const companionFormItem = document.querySelector(`.companion-form-${i}`);
      const companionName = companionFormItem?.querySelector(`input[name=acompanhante_${i}_nome`) as HTMLInputElement;
      const companionCPF = companionFormItem?.querySelector(`input[name=acompanhante_${i}_cpf`) as HTMLInputElement;
      const companionPassport = companionFormItem?.querySelector(`input[name=acompanhante_${i}_passaporte`) as HTMLInputElement;

      if(lang.lang === 'pt') {
        if (companionName.value !== "" || companionCPF.value !== "")
        companionSet.push({nome: companionName.value, cpf: companionCPF.value});
      }
      else {
        if (companionName.value !== "" || companionPassport.value !== "")
        companionSet.push({nome: companionName.value, passaporte: companionPassport.value});
      }
    }

    return companionSet;
  }

  const disableSubmitButton = () => {
    const submitButton = document.querySelector('input[type=submit]');
    submitButton?.setAttribute('disabled', '');
  }

  const ableSubmitButton = () => {
    const submitButton = document.querySelector('input[type=submit]');
    submitButton?.removeAttribute('disabled');
  }

  const inputPhoneMask = () => {
    const phoneInputs = document.querySelectorAll('[data-mask="telefone"]')
    phoneInputs.forEach((phoneInput) => {
      phoneInput.addEventListener('input', handleInput, false)

      function handleInput (e:any) {
        if (lang.lang === 'pt') {
          e.target.value = phoneMask(e.target.value)
        }
      }

      function phoneMask (phone:any) {
          return phone.replace(/\D/g, '')
            .replace(/^(\d)/, '($1')
            .replace(/^(\(\d{2})(\d)/, '$1) $2')
            .replace(/(\d{5})(\d{1,5})/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
      }
    })
  }

  const inputCnpjMask = () => {
    const cnpjInput = document.querySelector('[data-mask="cnpj"]')
    cnpjInput?.addEventListener('input', handleInput, false)

    function handleInput (e: any) {
      if (lang.lang === 'pt') {
        e.target.value = cnpjMask(e.target.value)
      }
    }

    function cnpjMask(cnpj: any){
        cnpj=cnpj.replace(/\D/g,"")
        
        if (cnpj.length > 14) {
          cnpj = cnpj.slice(0, 14)
        }
  
        cnpj=cnpj.replace(/^(\d{2})(\d)/,"$1.$2")
        cnpj=cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") 
        cnpj=cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")           
        cnpj=cnpj.replace(/(\d{4})(\d)/,"$1-$2")              
        return cnpj
    }


  }

  const inputCpfMask = () => {
    const cpfInputs = document.querySelectorAll('[data-mask="cpf"]')
    cpfInputs.forEach((cpfInput) => {
      cpfInput.addEventListener('input', handleInput, false)

      function handleInput (e:any) {
        if (lang.lang === 'pt') {
          e.target.value = cpfMask(e.target.value)
        }
      }

      function cpfMask(cpf: any){
        cpf=cpf.replace(/\D/g,"")
        
        if (cpf.length > 11) {
          cpf = cpf.slice(0, 11)
        }
        cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")       
        cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")       
                                                 
        cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        return cpf
      }
    })
  }

  // render
  return (
    <AgendamentosDiv>
      <Header
        animate={animate}
        menu={[]}
        subMenu={[]}
        type={2} 
      />

      {data && <PageInfo {...data} />}

      <AgendamentosForm>
        <FormFieldGroup>
          <FormFieldGroupTitle>
            { lang.lang === 'pt' ? 'Escritórios Administrativos:' : 'Administrative Offices:'}
          </FormFieldGroupTitle>

          <FormFieldInline>
            <FormSelect
              size={'mid'}
              name="escritorio"
              label={t('form.select.administrative_offices')}
              required={false}
              options={offices}
              labelField={'nome'}
              valueField={'id'}
              callbackFunction={(value: any) => {
                selectedOfficeId.current = value[0].id
                returnDates(selectedOfficeId.current)
              }}
            />
          </FormFieldInline>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormFieldGroupTitle>
            {lang.lang === 'pt' ? 'Selecione a data e o horário pretendidos:' : 'Select date and time:'}
          </FormFieldGroupTitle>

          <FormFieldInline>
            <div className='datepicker-wrapper'>
              <DatePicker
                showIcon
                name="data"
                selected={startDate.current}
                startDate={startDate.current}
                minDate={new Date()}
                includeDates={availableDates}
                onChange={(date: any) => {
                  startDate.current = date;
                  getDateTimes(date);
                }}
                className={`datepicker--input ${lang.lang}`}
                locale={lang.lang === "pt" ? ptBR : enUS}
                dateFormat={lang.lang === "pt" ? "dd MMMM yyyy" : "MMMM dd, yyyy"}
              />
            </div>
          
            <FormSelect
              size={'small'}
              name="horario"
              label={t('form.select.time')}
              required={false}
              options={availableHours}
              labelField={'hora'}
              valueField={'id'}
              callbackFunction={(value: any) => selectedDateId.current = value[0].id}
            />
          </FormFieldInline>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormFieldGroupTitle>
            { lang.lang === 'pt' ? '1. Dados da Empresa' : '1. Company Data'}
            <span className='required-fields'>Campos Obrigatórios</span>
          </FormFieldGroupTitle>

          <FormField
            name='empresa' 
            label={t('form.label.company_name')}
            inputType='text'
            required={true}
            placeholder=""
          />

          <FormFieldInline>
            { lang.lang === 'pt' ? 
            <FormField
              name='cnpj'
              label={t('form.label.company_cnpj')}
              inputType='text'
              required={true}
              placeholder=""
              dataMask="cnpj"
            />
            : ''}

            <FormField 
              name='telefone_empresa'
              label={t('form.label.company_phone')}
              inputType='tel'
              required={true}
              placeholder=""
              dataMask="telefone"
            />
          </FormFieldInline>

          <FormField 
            name='endereco'
            label={t('form.label.company_address')}
            inputType='text'
            required={true}
            placeholder=""
          />

          <FormSelect
            size={'large'}
            name={lang.lang === 'pt' ? 'categoria_pt' : 'categoria_en'}
            label={t('form.label.company_categories')}
            required={true}
            options={categories}
            labelField='nome'
            valueField='id'
            callbackFunction={(value: any) => selectedCategoryId.current = value[0].id}
          />
        </FormFieldGroup>

        <FormFieldGroup>
          <FormFieldGroupTitle>
          { lang.lang === 'pt' ? '2. Dados do Visitante' : '2. Visitor Data'}
          <span className='required-fields'>Campos Obrigatórios</span>
          </FormFieldGroupTitle>

          <FormField
            name='nome'
            label={t('form.label.full_name')}
            inputType='text'
            required={true}
            placeholder=""
          />

          <FormFieldInline>
            <FormField
              name='cargo'
              label={t('form.label.role')}
              inputType='text'
              required={true}
              placeholder=""
            />

            { lang.lang === 'pt' ?
            <FormField 
              name='rg'
              label={t('form.label.rg')}
              inputType='text'
              required={true}
              placeholder=""
            />
            : ''}
          </FormFieldInline>

          <FormFieldInline>
            <FormField
              name={lang.lang === 'pt' ? 'cpf' : 'passaporte'}
              label={t('form.label.cpf')}
              inputType='text'
              required={true}
              placeholder=""
              dataMask='cpf'
            />

            <FormField
              name='telefone'
              label={t('form.label.phone')}
              inputType='tel'
              required={true}
              placeholder=""
              dataMask='telefone'
            />
          </FormFieldInline>

          <FormField
            name='email'
            label={t('form.label.email')}
            inputType='email'
            required={true}
            placeholder=""
          />
        </FormFieldGroup>

        <FormFieldGroup className='companion-div'>
          <FormFieldGroupTitle>
          { lang.lang === 'pt' ? '3. Acompanhantes' : '3. Companion'}
          </FormFieldGroupTitle>

          <FormFieldInline className='companion-form-1'>
            <FormField
              name='acompanhante_1_nome'
              label={t('form.label.full_name')}
              inputType='text'
              required={false}
              placeholder=""
            />

            <FormField
              name={lang.lang === 'pt' ? 'acompanhante_1_cpf' : 'acompanhante_1_passaporte'}
              label={t('form.label.cpf')}
              inputType='text'
              required={false}
              placeholder=""
              dataMask='cpf'
            />
          </FormFieldInline>
        </FormFieldGroup>

        <Button value={lang.lang === 'pt' ? 'Adicionar acompanhante' : 'Add a companion'} className='add-companion' />

        <ReCAPTCHA
          className='captcha'          
          ref={recaptchaRef}
          sitekey={REACT_APP_GOOGLE_CAPTCHA_PUBLIC}
          onChange={ableSubmitButton}
          onExpired={disableSubmitButton}
          onErrored={disableSubmitButton}
        />

        <FormSubmitButton label={t('form.send')} disabled={true} />
      </AgendamentosForm>

      <FormFieldInline id='empty-div' style={{display: 'none'}}>
        <FormField
          name='acompanhante_prefix_nome'
          label={lang.lang === 'pt' ? 'Nome completo' : 'Full name'}
          inputType='text'
          required={false}
          placeholder=""
          />

        <FormField
          name={lang.lang === 'pt' ? 'acompanhante_prefix_cpf' : 'acompanhante_prefix_passaporte'}
          label={lang.lang === 'pt' ? 'CPF' : 'Passport'}
          inputType='text'
          required={false}
          placeholder=""
          />
      </FormFieldInline>
      
      <ResponseMessageDiv className='response-message'>
        <p>{t('form.sent_message')}</p>
      </ResponseMessageDiv>

      <Footer lang={lang} />
    </AgendamentosDiv>
  );
};

export default Agendamentos;