import styled from "styled-components";

const ContactInfoDiv = styled.div`
  background-color: ${({ theme }) => theme.white_800};
  padding: 60px;
  width: 50%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    order: 2;
    padding: 50px 35px;
    width: 100%;
  }
`;

const ContactInfoItemDiv = styled.div`
  float: left;
  margin: 0 0 30px;
  width: 100%;

  .description,
  .label,
  .number,
  .link {
    font-size: 16px;
  }

  .description {
    display: inline;
    margin: 0;
    line-height: 1.6em;
    font-weight: 400;
    white-space: pre-line;
    
    & + .link {
      display: inline;
      margin-left: 10px;
      top: 2px;
    }
  }

  .number {
    display: inline;
    font-weight: 600;
    line-height: 1.4em;
    top: 2px;
    white-space: nowrap;
  }

  .label {
    color: ${({ theme }) => theme.black_300};
    font-weight: 500;
    margin: 0 0 5px;
    width: 100%;
  }

  .link {
    color: ${({ theme }) => theme.blue_300};
    text-decoration: none;
    top: 1px;
  }

  &[data-type="3"] {
    .link {
      display: block;
      margin-left: 0;
    }
  }

  &[data-type="4"] {
    .description {
      white-space: pre-line;
    }
    
    .link {
      margin-left: 0;
      top: 3px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export {
  ContactInfoDiv,
  ContactInfoItemDiv,
}