import styled from "styled-components";

const MapBackgroundDiv = styled.div`
  background-color: ${({ theme }) => theme.green_600};
  left: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  .image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &--image {
      height: auto;
      left: 50%;
      max-width: 942px;
      object-fit: cover;
      position: absolute;
      top: 257px;
      transform: translate(-35%, 3%);
      width: 100%;
    }
  }

  @media only screen and (max-width : 1365px) {
    .background {
      &--image {
        top: calc(50% + 90px);
        transform: translate(-50%, -50%);
      }
  }
`;

export {
  MapBackgroundDiv
}