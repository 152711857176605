import styled from "styled-components";

const MapTitleModalDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  max-height: calc(80vh - 80px);
  max-width: 460px;
  left: calc(100% + 60px);
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  top: inherit;
  width: 460px;
  z-index: 99;

  .title-modal-body {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 33px 36px 38px;

    p {
      widht: 100%
      height: auto;
      padding: 15px 0;
      text-align: center;
      background-color: #DADADA;
      color: #434343;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      font-family: Montserrat;
      margin: 0;
    }

    .title-modal-body-information {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #A1D64A;

      p {
        max-width: 208px;
        width: 100%;
        background: transparent;
        line-height: 16px;
        text-align: left;
      }

      span {
        color: #068CA0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        font-family: Montserrat;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: 460px;
    max-height: calc(100vh - 100px);
    left: 50%;
    transform: translate(-50%, 120px);
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    max-width: 290px;
  }
`;

const MapTitleModalHeaderDiv = styled.div`
  background-color: ${({ theme }) => theme.green_900};
  border-radius: 5px;
  left: 8px;
  margin: 0;
  position: sticky;
  top: 8px;
  width: calc(100% - 16px);
  z-index: 2;

  .title-modal-button {
    background-color: transparent;
    border: none;
    right: 20px;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    .icon {
      color: ${({ theme }) => theme.green_700};
    }
  }

  .title {
    color: ${({ theme }) => theme.green_700};
    font-weight: 600;
    padding: 0 25px;
    width: 100%;
  }
`;

export {
  MapTitleModalDiv,
  MapTitleModalHeaderDiv,
}