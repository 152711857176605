import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

const H1 = styled.h1`
  font-size: 60px;
  font-weight: normal;
  line-height: 1.25em;

  .first,
  .text {
    color: inherit;
  }

  .first {
    font-weight: normal;
  }

  .text {
    font-weight: 200;
  }
`;

const H1Strong = styled.span`
  font-style: normal;
  font-weight: 800;
  font-size: 200px;
`;

const H2 = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
`;

const H2Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
`;

const H2Text = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
`;

const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  .strong {
    font-weight: bold;
    font-size: 20px;
  }
`;

const ParagraphTitle = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
`;

const TextMin = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

const TextSubtitleMin = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
`;

const SubTitle = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const LinkDefault = styled.a`
  color: ${({ theme }) => theme.white_900};
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  padding: 3px 10px;
  text-transform: uppercase;
  text-decoration: none;
`;

const LinkBg = styled.a`
  background-color: ${({ theme }) => theme.green_900};
  color: ${({ theme }) => theme.white_900};
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  padding: 3px 10px;
  text-transform: uppercase;
  text-decoration: none;
`;

const LinkClear = styled(LinkScroll)`
  color: ${({ theme }) => theme.white_900};
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  padding: 3px 10px;
  text-transform: uppercase;
  text-decoration: none;
`;

export {
  H1,
  H1Strong,
  H2,
  H2Text,
  H2Title,
  LinkDefault,
  LinkClear,
  LinkBg,
  Text,
  ParagraphTitle,
  TextMin,
  TextSubtitleMin,
  SubTitle
}