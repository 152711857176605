import styled from "styled-components";

const LoaderDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  height: 100vh;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  transition: opacity 400ms ease-in;
  width: 100vw;
  z-index: 999;

  &[data-animate="false"] {
    opacity: 0;
    pointer-events: none;
  }

  &[data-animate="true"] {
    opacity: 1;
  }
`;

const LoaderContainerDiv = styled.div`
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;

  svg {
    width: 350px;

    .block-1,
    .block-2,
    .block-3,
    .block-4,
    .block-5,
    g {
      opacity: 0;
      transition: opacity 400ms ease-in;
    }

    .block-1 {
      transition-delay: 200ms;
    }

    .block-2 {
      transition-delay: 400ms;
    }

    .block-3 {
      transition-delay: 600ms;
    }

    .block-4 {
      transition-delay: 800ms;
    }

    .block-5 {
      transition-delay: 900ms;
    }

    g {
      opacity: 0;
      transition-delay: 900ms;
    }
  }

  &[data-animate="true"] {
    svg {
      .block-1,
      .block-2,
      .block-3,
      .block-4,
      .block-5,
      g {
        opacity: 1;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    svg {
      width: 210px;
    }
  }
`;

export {
  LoaderDiv,
  LoaderContainerDiv
}