import styled from "styled-components";

const ContactSection = styled.section`
  padding: 0 0 50px;
  width: 100%;
`;

const ContactTitleDiv = styled.div`
  padding: 60px 0;
  width: 100%;

  h2 {
    color: ${({ theme }) => theme.green_300};
    margin: 0;
    text-align: center;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 45px 35px 25px;
    
    h2 {
      text-align: left;
    }
  }
`;

const ContactContainerDiv = styled.div`
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    display: inline-flex;
    flex-flow: row wrap;
  }
`;

export {
  ContactSection,
  ContactTitleDiv,
  ContactContainerDiv
}