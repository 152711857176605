import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '../../Typography/styles';
import { NewsItemContentDiv, NewsItemDiv, NewsItemTitleDiv } from './styles';

import { INewsItem } from './interfaces';

// news item
const NewsItem: FC<INewsItem> = ({
  date,
  link,
  description,
  type,
  title,
}) => {
  const { t } = useTranslation();

  // render
  return (
    <NewsItemDiv>
      <NewsItemTitleDiv>
        <Text className="type">{type}</Text>
        <Text className="date">{date}</Text>
      </NewsItemTitleDiv>
      <NewsItemContentDiv>
        <Text className="title">{title}</Text>
        <Text className="description">{description}</Text>
      </NewsItemContentDiv>

      {link && <a
        href={link}
        className="link"
        target="_blank"
        rel="noreferrer">{t('read.more')}</a>}
    </NewsItemDiv>
  );
};

export default NewsItem;