import React, { FC, useContext, useEffect } from "react";
import useBlockScroll from "../../hooks/useBlockScroll";

import { LangContext } from "../../providers/LangProvider";

import NavbarMenu from "./NavbarMenu";
import NavbarSubMenu from "./NavbarSubMenu";
import SelectLang from "../SelectLang";

import { NavbarDiv, NavbarMenuDiv, NavbarMenuTopDiv } from "./styles";
import { INavbar } from "./interfaces";

// navbar
const Navbar: FC<INavbar> = ({
  active,
  menu,
  subMenu,
  open,
}) => {
  const { blockScroll, allowScroll } = useBlockScroll();
  const { lang, langs, setLang } = useContext(LangContext);

  // open
  useEffect(() => {
    if (open === true) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [ allowScroll, blockScroll, open ]);

  // render
  return (
    <NavbarDiv
      active={active}
      data-open={open}>
      <NavbarMenuTopDiv>  
        {subMenu && <NavbarSubMenu
          lang={lang}
          items={subMenu} />}

        <SelectLang
          lang={lang}
          langs={langs}
          setLang={setLang} />
      </NavbarMenuTopDiv>

      {menu && <NavbarMenuDiv>
        <NavbarMenu
          items={menu} />
      </NavbarMenuDiv>}
    </NavbarDiv>
  );
};

export default Navbar;