export const subjects = [
  {
    "value": 5,
    "label": "Selecione",
    "ordem": 5
  },
  {
    "value": 1,
    "label": "Demandas de imprensa",
    "ordem": 10
  },
  {
    "value": 2,
    "label": "Fornecedores e servicos",
    "ordem": 20
  }, {
    "value": 5,
    "label": "vagas e oportunvalueades de trabalho"
  }, 
  {
    "value": 3,
    "label": "trabalhe conosco",
    "ordem": 30
  },
  {
    "value": 4,
    "label": "Outros assuntos",
    "ordem": 40
  }
];