import React, { FC } from 'react';
import MapItem from '../MapItem';
import MapTitleModal from '../MapTitleModal';
import { SubTitle } from '../../Typography/styles';
import { MapListContainerDiv, MapListDiv, MapListTitleDiv } from './styles';
import { IMapList } from './interfaces';
import { ReactComponent as PotiguarIcon } from '../../../assets/icons/potiguar-icon.svg';
import { ReactComponent as ReconcavoIcon } from '../../../assets/icons/reconcavo-icon.svg';
import { ReactComponent as OffshoreIcon } from '../../../assets/icons/offshore-icon.svg';

interface MapListProps extends IMapList {
  activeTitleModal: string | null;
  setActiveTitleModal: (title: string | null) => void;
  activeItemModal: any;
  setActiveItemModal: (item: any) => void;
}

// Map list
const MapList: FC<MapListProps> = ({
  image,
  current,
  items,
  posModal,
  setCurrent,
  title,
  title_modal,
  modal_text,
  modal_information_1,
  modal_information_2,
  modal_information_value_1,
  modal_information_value_2,
  activeTitleModal,
  setActiveTitleModal,
  activeItemModal,
  setActiveItemModal,
}) => {
  const renderIcon = () => {
    switch (image) {
      case 'potiguar-icon':
        return <PotiguarIcon />;
      case 'reconcavo-icon':
        return <ReconcavoIcon />;
      case 'offshore-icon':
        return <OffshoreIcon />;
      default:
        return null;
    }
  };

  const handleOpenTitleModal = () => {
    setActiveTitleModal(title_modal);
  };

  return (
    <MapListDiv>
      <MapListTitleDiv>
        <div className='title-content'>
          <div className={`title-wrapper ${image}`}>
            <div className='text-content'>
              {renderIcon()}
              <span className="text">{title}</span>
            </div>
            <button
              className="title-modal-button"
              onClick={handleOpenTitleModal}
            >
              <span className="icon icon-arrow-bold"></span>
            </button>
          </div>
        </div>
      </MapListTitleDiv>

      {activeTitleModal === title_modal && (
        <MapTitleModal
          title_modal={title_modal}
          onClose={() => setActiveTitleModal(null)}
          modal_text = {modal_text}
          modal_information_1 = {modal_information_1}
          modal_information_2 = {modal_information_2}
          modal_information_value_1 = {modal_information_value_1}
          modal_information_value_2 = {modal_information_value_2}
        />
      )}

      <MapListContainerDiv>
        {items && items.map((item, index) =>
          <MapItem
            active={current === item}
            item={item}
            posModal={posModal}
            setCurrent={setCurrent}
            setActiveItemModal={setActiveItemModal}
            activeItemModal={activeItemModal}
            key={index} />)}
      </MapListContainerDiv>
    </MapListDiv>
  );
};

export default MapList;