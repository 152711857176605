import React, { FC, ReactNode } from "react";
import Moment from 'react-moment';

import { TableDiv, TableContainer } from "./styles";

const Table: FC<any> = ({ searchResults }) => {
  const formatCurrency = (price: any) => {
    let newPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 4,
    }).format(price);
    
    return newPrice;
  }

  return (
    <>
      <TableDiv>
        <TableContainer>
          <thead>
            <tr>
              <th>Local</th>
              <th>Modalidade de Venda</th>
              <th>Data</th>
              <th>Preço</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(searchResults) &&
              searchResults.map((item) => (
                <tr key={item.id}>
                  <td>{item.local}</td>
                  <td>{item.modalidade_venda}</td>
                  <td><Moment date={item.data} format={'DD/MM/YYYY'}/></td>
                  <td>{formatCurrency(item.preco)}</td>
                </tr>
              ))}
          </tbody>
        </TableContainer>
      </TableDiv>
    </>
  );
};

export default Table;
