import styled from "styled-components";

const WebdoorContentDiv = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: auto;
  top: 0;
  width: 100%;
  z-index: 2;
  user-select: none;
`;

export {
  WebdoorContentDiv,
}