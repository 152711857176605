import styled from 'styled-components';

const WebdoorInfoDiv = styled.div`
  display: inline-flex;
  height: 100%;
  pointer-events: none;
  width: 100%;
  z-index: 10;

  > div {
    height: 100%;
  }

  .webdoor-info--container {
    left: 0;
    height: 100%;
    max-width: 795px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 1024px) {
    padding: 35px;

    .webdoor-info--container {
      left: initial;
      position: relative;
      top: initial;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    bottom: 0px;
    height: auto;
    padding: 60px 20px 30px;
    position: absolute;

    p {
      margin: 20px 0 0;
    }

    .webdoor-info-item--link {
      display: none;
    }
  }
`;

export {
  WebdoorInfoDiv,
}