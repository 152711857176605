import styled from "styled-components";

const HeaderInternaDiv = styled.div`
  float: right;
  padding: 18px 0;
  display: flex;
  align-items: center;
  position: initial;

  .link-redirect {
    background: ${({ theme }) => theme.green_700};
    padding: 10px 20px;
    margin: 0 50px 0 0;
    border: 2px solid ${({ theme }) => theme.green_700};
    border-radius: 5px;
    color: ${({ theme }) => theme.green_900};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: 768px) {
      padding: 7px 15px;
      font-size: 11px;
      line-height: 11.5px;
      position: absolute;
      bottom: 11px;
      left: 20px;
    }
  }

  .link-return {
    color: ${({ theme }) => theme.green_700};
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: inline-block;
    margin: 0 50px 0 0;
    text-decoration: none;
    text-transform: uppercase;

    @media (min-width: 769px) and (max-width: 1024px) {
      margin: 0 10px 0 0;
    }

    @media (max-width: 768px) {
      margin: 0;
      position: absolute;
      bottom: 16px;
      right: 20px;
    }

    @media (max-width: 480px) {
      max-width: 110px;
      bottom: 11px;
    }
  }

  &[has-button=false] {
    .link-redirect-button {
      display: none;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 8px 20px 0 0;
    text-align: right;

    .link-redirect {
      margin-right: 20px;
    }

    .link-return {
      text-align: left;
      width: fit-content;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 15px;
    padding-right: 30px;
  }
`;

export {
  HeaderInternaDiv
}