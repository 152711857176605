import React, { FC, useEffect, useState } from 'react';
import { Events, Link as LinkScroll } from "react-scroll";

import Navbar from '../../components/Navbar';
import HeaderInterna from './HeaderInterna';

import useWindowSize from '../../hooks/useWindowSize';

import { ContainerDiv } from "../Container/styles";
import { HeaderHeader, HeaderLogoDiv, HeaderOpenButton } from './styles';

import { ReactComponent as Logo } from "../../assets/icons/logotipo.svg";

import { IHeader } from './interfaces';

// header
const Header: FC<IHeader> = ({
  animate,
  menu,
  subMenu,
  type
}) => {
  const [ active, setActive ] = useState<boolean>(false);
  const [ open, setOpen ] = useState<boolean>(false);

  const { width } = useWindowSize();

  // use effect
  useEffect(() => {
    Events.scrollEvent.register('begin', () => setOpen(false));

    window.onscroll = () => {
      if (window.pageYOffset >= 200 && width >= 768) {
        setActive(true);
      } else if (window.pageYOffset >= 100 && width < 768) {
        setActive(true);
      } else {
        setActive(false);
      }
    };
  }, [ width ]);

  // render
  return (
    <HeaderHeader
      data-animate={animate}
      data-active={active}
      data-open={open}
      data-type={type}>
      <ContainerDiv>
        <HeaderLogoDiv>
          <LinkScroll
            activeClass="active"
            to="webdoor"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            tabIndex={0}>
            <Logo />
          </LinkScroll>
        </HeaderLogoDiv>

        {type !== 2 &&<Navbar
          active={active}
          menu={menu}
          subMenu={subMenu}
          open={open} />}

        {type === 2 && <HeaderInterna />}

        {type !== 2 && <HeaderOpenButton
          data-active={open}
          onClick={() => setOpen(!open)}>
          <span className="icon icon-hamburguer"></span>
          <span className="icon icon-times"></span>
        </HeaderOpenButton>}
      </ContainerDiv>
    </HeaderHeader>
  );
};

export default Header;