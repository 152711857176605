import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';

import AsgItem from './AsgItem';
import AsgTitle from './AsgTitle';

import { IAsg } from './interfaces';

import { ContainerDiv } from '../../layout/Container/styles';
import { AsgContainerDiv, AsgSection } from './styles';

// asg
const Asg: FC<IAsg> = ({
  lang
}) => {
  const [ data, setData ] = useState<any>(null);

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`data/asg-${lang.lang}.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, [ lang ]);

  // render
  return (
    <AsgSection id="asg">
      <ContainerDiv>
        <AsgTitle {...data} />

        <AsgContainerDiv>
          {Array.isArray(data?.items) && data?.items.map((item: any, index: number) =>
            <AsgItem {...item} key={index} />)}
        </AsgContainerDiv>
      </ContainerDiv>
    </AsgSection>
  );
};

export default Asg;